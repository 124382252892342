import ApiClient from './apiClient';

const fetchBillingDetails = async () =>
  ApiClient().get('/billing/details', {
    data: {},
  });

const pollForRole = async () =>
  ApiClient().get('/billing/pro/poll', {
    data: {},
  });

const fetchBillingPlans = async () =>
  ApiClient().get('/billing/plans', {
    data: {},
  });

const cancelSubscription = async () =>
  ApiClient().post('/billing/cancel', {
    data: {},
  });

const reactivateSubscription = async () =>
  ApiClient().post('/billing/reactivate', {
    data: {},
  });

const postBillingPay = async (data: any) =>
  ApiClient().post('/billing/pay', data);

const fetchBillingHistory = async () =>
  ApiClient().get('/billing/payments', {
    data: {},
  });

const validateCouponCode = async (couponCode: string) =>
  ApiClient().get(`/billing/validate_coupon?coupon_code=${couponCode}`);

const Api = {
  pollForRole,
  validateCouponCode,
  fetchBillingPlans,
  postBillingPay,
  fetchBillingDetails,
  fetchBillingHistory,
  cancelSubscription,
  reactivateSubscription,
};
export default Api;
