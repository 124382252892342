import ApiClient from 'shared/api/apiClient';

type PasskeyData = {
  name: string;
};

export const fetchPasskeys = async () => ApiClient().get(`/passkeys`);

export const createPasskey = async () =>
  ApiClient().get(`/passkeys/create-credential`);

export const addPasskey = async attestationResponse =>
  ApiClient().post(
    `/passkeys/add-credential`,
    JSON.stringify(attestationResponse),
  );

export const editPasskey = async (id: string, data: PasskeyData) =>
  ApiClient().patch(`/passkeys/${id}`, data);

export const deletePasskey = async (id: string) =>
  ApiClient().delete(`/passkeys/${id}`);

export const PasskeysApi = {
  fetchPasskeys,
  createPasskey,
  addPasskey,
  editPasskey,
  deletePasskey,
};
