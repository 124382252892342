// Helpers
import { IS_FOUNDATION } from 'shared/config/Config';
// Imported SVG Elements
import LogoFullFoundation from 'shared/images/foundation-full-logo.svg';
import FoundationText from 'shared/images/foundation-text.svg';
import LogoFullDefault from 'shared/images/logo-full-2.svg';
import SVGLogoIcon from 'shared/images/icon-logo.svg';
import SVGLogoText from 'shared/images/logo-text.svg';
import EnterpriseText from 'shared/images/logo-text-enterprise.svg';
import ProLogoText from 'shared/images/logo-text-pro.svg';

const LOGO_FULL = IS_FOUNDATION ? LogoFullFoundation : LogoFullDefault;
const LOGO = IS_FOUNDATION ? LogoFullFoundation : SVGLogoIcon;
const TEXT_LOGO = IS_FOUNDATION ? FoundationText : SVGLogoText;
const ENTERPRISE_LOGO_TEXT = EnterpriseText;
const PRO_LOGO_TEXT = ProLogoText;
export { LOGO_FULL, LOGO, TEXT_LOGO, ENTERPRISE_LOGO_TEXT, PRO_LOGO_TEXT };
