import React from 'react';
import styled from 'styled-components';

interface SupportedBrowsersProps {
  className?: string;
  iconSize?: string;
}

const SupportedBrowsers: React.FC<SupportedBrowsersProps> = ({
  className,
  iconSize = '32',
}) => {
  return (
    <Container className={className}>
      <BrowserLogo
        src="/browsers/chrome.png"
        alt=""
        width={iconSize}
        height={iconSize}
      />
      <BrowserLogo
        src="/browsers/safari.png"
        alt=""
        width={iconSize}
        height={iconSize}
      />
      <BrowserLogo
        src="/browsers/edge.png"
        alt=""
        width={iconSize}
        height={iconSize}
      />
      <BrowserLogo
        src="/browsers/opera.png"
        alt=""
        width={iconSize}
        height={iconSize}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const BrowserLogo = styled.img``;

export default SupportedBrowsers;
