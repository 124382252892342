import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon, Color, Size } from '@packages/ui';
// import { Button, Icon, Color, Size } from '@packages/ui';

const EmailConfirmation = () => {
  const { t } = useTranslation('portal');

  return (
    <FormWrapper>
      <LetterIcon icon="confirmationLetter" iconSize={230} />
      <MainText>{t('Congratulations!')}</MainText>
      <SecondaryTexts>
        {t(
          'You have successfully signed up for an account with hCaptcha. Please check your inbox for a verification email and further information to get started.',
        )}
      </SecondaryTexts>
      {/* <ActionContainer>
        <SecondaryTexts>
          {t('To re-send the verification email, click the button below.')}
        </SecondaryTexts>
        <StyledButton dataCy="createOrg">
          {t('Send Verification')}
        </StyledButton>
      </ActionContainer> */}
    </FormWrapper>
  );
};

// HTML Elements
const LetterIcon = styled(Icon)`
  margin-bottom: -40px;
`;

const FormWrapper = styled.div`
  width: 280px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${Size.tablet}) {
    width: 560px;
  }
`;

const MainText = styled.p`
  font-weight: 600;
  font-size: 36px;
  line-height: 5px;
  text-align: center;
`;

const SecondaryTexts = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${Color.grey600};
`;

/* const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  border-top: 1px dashed ${Color.grey300};
  padding-top: 10px;
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  padding: 8px 16px;
  gap: 8px;
  width: 153px;
  height: 40px;
  border-radius: 4px;
  color: ${Color.blue400};
  border: 1px solid ${Color.blue400};
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  background-color: transparent;
  &:hover {
    color: ${Color.white};
  }
`; */

export default EmailConfirmation;
