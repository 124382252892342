export const getPathSegment = (
  baseRoute: string,
  path?: string,
  // eslint-disable-next-line consistent-return
) => {
  if (path) {
    const segments = path.split(`/${baseRoute}/`);
    return segments[1];
  }
};
