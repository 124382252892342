import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SearchField } from '@packages/ui';

import { getInputValueTrimmed } from 'shared/utils/regexUtils';

interface AccountSearchProps {
  onSearch: (searchInput: string) => Promise<void>;
  onClear: Function;
}

export default function AccountSearch({
  onSearch,
  onClear,
}: AccountSearchProps): React.ReactElement {
  const { t } = useTranslation('generic');
  const [inputValue, setInputValue] = useState('');

  const isFirstRenderSkipped = useRef(false);

  useEffect(() => {
    const getSearch = async () => {
      await onSearch(inputValue);
    };

    if (!inputValue.length && isFirstRenderSkipped.current) {
      onClear();
    }

    const debounce = inputValue.length ? setTimeout(getSearch, 1000) : null;

    if (!isFirstRenderSkipped.current) {
      isFirstRenderSkipped.current = true;
    }

    return () => {
      if (debounce) {
        clearTimeout(debounce);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleInputChange = (input: string) => {
    setInputValue(getInputValueTrimmed(input));
  };

  return (
    <StyledSearchField
      placeholder={t('Search account resource (e.g. email, sitekey)')}
      value={inputValue}
      onChange={handleInputChange}
      onClear={onClear}
    />
  );
}

const StyledSearchField = styled(SearchField)`
  margin: 24px 0;
`;
