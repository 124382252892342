/**
 * This function parses the response based on the content type.
 *
 * @param {Response} res - The response object to parse.
 * @returns {Promise<any>} - The parsed response data.
 */
export async function getData(res: Response): Promise<any> {
  const type = res.headers.get('Content-Type');

  if (type?.includes('application/json')) {
    return res.json();
  }
  if (type?.includes('application/x-www-form-urlencoded')) {
    const data = await res.text();
    return new URLSearchParams(data);
  }

  return res.text();
}
