import * as Sentry from '@sentry/browser';
import { noop } from 'lodash';

const createLog = (
  level: 'log' | 'error' | 'warn' | 'info',
): ((...args: any[]) => void) => {
  const isErrorLog = level === 'error';

  /* eslint-disable no-console */
  const log = isErrorLog
    ? Sentry && Sentry.captureException
    : console && (console[level] || console.log);

  /* eslint-enable no-console */
  if (log) {
    return isErrorLog
      ? (...error) =>
          Sentry.captureException(parseConsoleError(error), {
            level: Sentry.Severity.Error,
          })
      : log.bind(console);
  }

  function parseConsoleError(args) {
    let errorMsg = args[0];

    if (!(errorMsg instanceof Error)) {
      errorMsg = new Error(
        args.reduce(
          (previousValue, currentValue) => `${previousValue} ${currentValue}`,
          '',
        ),
      );
    }

    return errorMsg;
  }

  return noop;
};

const Logger = {
  log: createLog('log'),
  info: createLog('info'),
  error: createLog('error'),
  warn: createLog('warn'),
};
export default Logger;
