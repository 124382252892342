const COLORS = [
  '#0bb4ff',
  '#e60049',
  '#50e991',
  '#e6d800',
  '#9b19f5',
  '#ffa300',
  '#dc0ab4',
  '#b3d4ff',
  '#00bfa0',
];

const generateColor = (index?: number) => {
  if (typeof index === 'number' && index <= COLORS.length) return COLORS[index];
  let color = '#';
  for (let i = 0; i < 3; i += 1)
    color += `0${Math.floor(Math.random() * 200).toString(16)}`.slice(-2);
  return color;
};

export default generateColor;
