import React, { useState, memo, useEffect, useRef } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { Metric, useAnalytics } from '@packages/analytics';
import {
  Text,
  Color,
  Size,
  Button,
  Loader,
  InputField,
  Icon,
} from '@packages/ui';

import { getStripe } from 'main/data/api';
import { hCaptcha, IS_FOUNDATION, IS_LOCAL_ENV } from 'shared/config/Config';
// Models
import Logger from 'shared/models/Logger';
import History from 'shared/models/History';
import { useRootStore } from 'shared/stores';

export interface PaymentFormProps {
  className?: string;
  title?: string;
  urlCouponCode?: string;
  onDiscountApply: (newValue: number) => void;
}

const hCaptchaProps = {
  endpoint: hCaptcha.endpoint,
  custom: IS_FOUNDATION,
  ...(IS_LOCAL_ENV
    ? {
        host: 'dashboard.local',
      }
    : {}),
};

enum CardElementType {
  CARD_NUMBER = 'cardNumber',
  CARD_EXPIRY = 'cardExpiry',
  CARD_CVC = 'cardCvc',
}

type CardDataInterface = {
  [key in CardElementType]: {
    isLoading: boolean;
    isComplete: boolean;
    error?: string;
  };
};

const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: Color.grey800,
      border: `1px solid ${Color.grey200}`,
      borderRadius: '4px',
      fontSize: '16px',
      fontWeight: 400,
      fontSmoothing: 'antialiased',
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
        Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif`,
      '::placeholder': {
        color: Color.grey400,
      },
    },
    invalid: {
      color: Color.red400,
    },
  },
};

const PaymentForm: React.FC<PaymentFormProps> = observer(
  ({ className, title, urlCouponCode, onDiscountApply }) => {
    const { t } = useTranslation('portal');
    const { trackMetric } = useAnalytics();
    const elements = useElements();
    const stripe = useStripe();
    const captchaRef = useRef<HCaptcha>(null);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [isCouponCodeValid, setIsCouponCodeValid] = useState<boolean | null>(
      null,
    );
    const [isCouponValidationLoading, setIsCouponValidationLoading] = useState(
      false,
    );
    const [couponCode, setCouponCode] = useState(urlCouponCode || '');
    const isCouponCodeError = isCouponCodeValid === false;

    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentError, setPaymentError] = useState<string | undefined>();
    const { userStore, subscriptionStore } = useRootStore();
    const [cardData, setCardData] = useState<CardDataInterface>({
      cardNumber: {
        isLoading: true,
        isComplete: false,
        error: '',
      },
      cardExpiry: {
        isLoading: true,
        isComplete: false,
        error: '',
      },
      cardCvc: {
        isLoading: true,
        isComplete: false,
        error: '',
      },
    });
    const isPaymentCardLoading = Object.values(cardData).some(
      ({ isLoading }) => isLoading,
    );
    const isSubmitButtonDisabled =
      !stripe ||
      !elements ||
      isProcessing ||
      subscriptionStore.isLoading ||
      (!isCouponCodeValid && couponCode);

    const handleCouponCodeChange = code => {
      setCouponCode(code);
      setIsCouponCodeValid(null);
      onDiscountApply(0);
    };

    const isCardError = () => {
      return (
        !!cardData.cardCvc.error ||
        !!cardData.cardExpiry.error ||
        !!cardData.cardNumber.error
      );
    };

    const validateCouponCode = async code => {
      try {
        if (!code) {
          return;
        }

        setIsCouponValidationLoading(true);
        const {
          isValid,
          discountPercentage,
        } = await subscriptionStore.validateCouponCode(code);

        if (isValid) {
          setIsCouponCodeValid(true);
          onDiscountApply(discountPercentage);
        } else {
          setIsCouponCodeValid(false);
        }
        setIsCouponValidationLoading(false);
      } catch (error) {
        Logger.error(`[Pro] couldn't apply the Pro plan promo code: ${error}`);
      }
    };

    useEffect(() => {
      if (urlCouponCode) {
        validateCouponCode(urlCouponCode);
      }
    }, [urlCouponCode]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCardElementChange = event => {
      setCardData({
        ...cardData,
        [event.elementType]: {
          ...cardData[event.elementType],
          isComplete: event.complete,
          error: event.error?.message || '',
        },
      });
    };

    const handleCardElementReady = (elementType = '') => {
      setCardData({
        ...cardData,
        [elementType]: {
          ...cardData[elementType],
          isLoading: false,
        },
      });
    };

    const fetchSubscriptionKey = async (token: string) => {
      if (!subscriptionStore.selectedPlan) {
        return null;
      }

      try {
        const subscriptionKey = await subscriptionStore.proceedSubscription(
          subscriptionStore.selectedPlan,
          false,
          couponCode,
          token,
        );
        return subscriptionKey;
      } catch (error) {
        Logger.error(
          `couldn't proceed with the Pro plan subscription: ${error}`,
        );

        return null;
      }
    };

    const trackProPlanPurchase = () => {
      trackMetric(Metric.PRO_PLAN_PURCHASE_CLICK, {
        plan: subscriptionStore.selectedPlan,
      });
    };

    const pollForPro = async () => {
      try {
        await subscriptionStore.isUserPro();
      } catch (error) {
        Logger.error(`Pro Plan polling failed with error: ${error}`);
        setPaymentError(
          'Subscription issue occurred. Please try again or contact support@hcaptcha.com',
        );
        setIsProcessing(false);
      }
    };

    const getValidationError = () => {
      if (!name) {
        return t('Please specify your full name.');
      }

      if (!stripe || !elements) {
        return t('Payment error. Please contact support@hcaptcha.com');
      }

      return '';
    };

    const handlePaymentSetup = async (token: string) => {
      const card = elements?.getElement(CardNumberElement);
      if (!card || isCardError()) {
        setPaymentError(t('Invalid card data.'));
        setIsProcessing(false);

        return;
      }

      const subscriptionKey = await fetchSubscriptionKey(token);

      if (!subscriptionKey || !stripe) {
        setPaymentError(
          'Subscription issue occurred. Please try again or contact support@hcaptcha.com',
        );
        setIsProcessing(false);

        return;
      }

      // "confirmCardSetup" is used for the Free Trial
      const confirmPaymentMethod = subscriptionStore.isCardSetupIntent
        ? 'confirmCardSetup'
        : 'confirmCardPayment';

      const payload = await stripe[confirmPaymentMethod](subscriptionKey, {
        payment_method: {
          card,
          billing_details: {
            name,
            email: userStore.getEmail(),
          },
          metadata: {
            company,
          },
        },
      });

      if (payload.error) {
        const message = payload.error?.message;

        setPaymentError(message);
        setIsProcessing(false);
      } else {
        await pollForPro();
        History.replace('/payment/success');
      }
    };

    const handleCaptchaError = () => {
      setPaymentError('An error occurred, please try again');
      setIsProcessing(false);
    };

    const handleCaptchaVerify = async (token: string) => {
      try {
        const validationError = getValidationError();

        setPaymentError(validationError);

        if (validationError) {
          return;
        }

        await handlePaymentSetup(token);
      } catch (error) {
        setPaymentError(
          t(
            'Plan setup issue occurred. Please try again or contact support@hcaptcha.com',
          ),
        );
        Logger.error(`couldn't set up the Pro plan: ${error}`);
      }
    };

    const handleSubmit = async event => {
      setIsProcessing(true);
      event.preventDefault();

      trackProPlanPurchase();

      if (captchaRef.current) {
        captchaRef.current.execute();
      }
    };

    if (
      subscriptionStore.isLoading &&
      !subscriptionStore.subscriptionPlans?.length
    ) {
      return <Loader size="m" />;
    }

    return (
      <FormWrapper className={className}>
        <HCaptcha
          sitekey={hCaptcha.fraudBillingCaptchaSitekey}
          onError={handleCaptchaError}
          onVerify={handleCaptchaVerify}
          size="invisible"
          ref={captchaRef}
          // eslint-disable-next-line
          {...hCaptchaProps}
        />
        {title && <Text variant="h3">{title}</Text>}
        {!stripe && (
          <ErrorMessage>
            <WarningIcon>!</WarningIcon>
            {t(
              'Stripe is not accessible at the moment. Please, try again later',
            )}
          </ErrorMessage>
        )}
        {paymentError && (
          <ErrorMessage>
            <WarningIcon>!</WarningIcon>
            {paymentError}
          </ErrorMessage>
        )}
        <Label>
          Full Name
          <InputField
            id="name"
            isRequired
            placeholder={t('Example: {{example}}', {
              example: 'John Smith',
            })}
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </Label>
        <Label>
          Company <i>(optional)</i>
          <InputField
            id="company"
            placeholder="Intuition Machines Inc."
            value={company}
            onChange={event => setCompany(event.target.value)}
          />
        </Label>
        {stripe ? (
          <>
            {isPaymentCardLoading && (
              <PaymentLoaderWrapper>
                <Loader size="xs" />
              </PaymentLoaderWrapper>
            )}
            <PaymentCardInfo isHidden={isPaymentCardLoading}>
              <PaymentCardNumber>
                Card number
                <CardNumberElement
                  id="cardNumber"
                  options={ELEMENT_OPTIONS}
                  onChange={handleCardElementChange}
                  onReady={() =>
                    handleCardElementReady(CardElementType.CARD_NUMBER)
                  }
                />
              </PaymentCardNumber>
              <PaymentCardCvc>
                CVC
                <CardCvcElement
                  id="cvc"
                  options={{
                    ...ELEMENT_OPTIONS,
                    placeholder: '123',
                  }}
                  onChange={handleCardElementChange}
                  onReady={() =>
                    handleCardElementReady(CardElementType.CARD_CVC)
                  }
                />
              </PaymentCardCvc>
              <PaymentCardExpiry>
                Expiration
                <CardExpiryElement
                  id="expiry"
                  options={{
                    ...ELEMENT_OPTIONS,
                    placeholder: '01/22',
                  }}
                  onChange={handleCardElementChange}
                  onReady={() =>
                    handleCardElementReady(CardElementType.CARD_EXPIRY)
                  }
                />
              </PaymentCardExpiry>
            </PaymentCardInfo>
          </>
        ) : null}
        <CouponCodeInfo>
          <Label>
            Promo Code
            <CouponCodeContainer>
              <InputField
                id="coupon-code"
                isError={isCouponCodeError}
                value={couponCode}
                onChange={event => handleCouponCodeChange(event.target.value)}
              />
              <IconWrapper>
                {isCouponCodeValid && (
                  <Icon icon="successCheckmark" iconSize="16" />
                )}
                {isCouponCodeError && couponCode && (
                  <Icon icon="xIconAlternate" iconSize="16" />
                )}
              </IconWrapper>
            </CouponCodeContainer>
          </Label>
          <ApplyButton
            variant="default"
            onClick={() => validateCouponCode(couponCode)}
            disabled={!couponCode}
          >
            {isCouponValidationLoading ? t('Validating...') : t('Apply')}
          </ApplyButton>
          {isCouponCodeError && couponCode && (
            <InvalidCouponMessage>
              {t('Invalid Promo Code.')}
            </InvalidCouponMessage>
          )}
        </CouponCodeInfo>
        <ActionsContainer>
          <PurchaseButton
            variant="default"
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
          >
            {isProcessing ? t('Processing...') : t('Purchase')}
          </PurchaseButton>
        </ActionsContainer>
      </FormWrapper>
    );
  },
);

const StripePaymentForm: React.FC<PaymentFormProps> = ({
  className,
  title,
  urlCouponCode,
  onDiscountApply,
}) => {
  return (
    <Elements stripe={getStripe()}>
      <PaymentForm
        onDiscountApply={onDiscountApply}
        urlCouponCode={urlCouponCode}
        className={className}
        title={title}
      />
    </Elements>
  );
};

const Label = styled.label`
  margin-top: 16px;
  display: block;
`;

const PaymentCardInfo = styled.div<{
  isHidden?: boolean;
}>`
  height: ${({ isHidden }) => (isHidden ? '0' : 'auto')};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 400px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const CouponCodeInfo = styled.div<{
  isHidden?: boolean;
}>`
  height: ${({ isHidden }) => (isHidden ? '0' : 'auto')};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 400px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const PaymentCardNumber = styled(Label)`
  width: 100%;

  @media (min-width: 600px) {
    width: 53%;
  }
`;

const CouponCodeContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8x;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
  bottom: 12px;
`;

const PaymentCardCvc = styled(Label)`
  width: 100%;

  @media (min-width: 400px) {
    width: 20%;
    display: inline-block;
  }

  @media (min-width: 600px) {
    width: 14%;
  }
`;

const PaymentCardExpiry = styled(Label)`
  width: 100%;

  @media (min-width: 400px) {
    width: 75%;
    display: inline-block;
  }

  @media (min-width: 600px) {
    width: 28%;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
const ApplyButton = styled(Button)`
  width: 30%;
  height: 30%;
  margin-top: 45px;
`;
const PurchaseButton = styled(Button)`
  width: 100%;
`;

const FormWrapper = styled.div`
  input,
  .StripeElement {
    margin-top: 8px;
    padding: 9px ${Size.space200};
    color: ${Color.grey800};
    background-color: ${Color.white};
    border-radius: ${Size.radius200};
    border: 1px solid ${Color.grey200};
    outline: none;
    font-size: var(--defaultFontSize);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      sans-serif;

    &:hover {
      border-color: ${Color.grey300};
    }

    &:disabled {
      background-color: ${Color.grey100};
      color: ${Color.grey800};
      border-color: ${Color.grey100};
    }

    &::placeholder {
      color: ${Color.grey400};
      opacity: 1;
    }
  }
`;

const WarningIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid;
  flex-shrink: 0;
`;

const PaymentLoaderWrapper = styled.div`
  height: 84px;
`;

const ErrorMessage = styled(Text)`
  width: 100%;
  color: ${Color.red400};
  margin: 0 auto 10px;
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin-top: 16px;
`;

const InvalidCouponMessage = styled(Text)`
  color: ${Color.red400};
  font-size: 11px;
`;

export default memo(StripePaymentForm);
