import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@packages/ui';

import History from 'shared/models/History';

export interface PageTabsV2Props {
  className?: string;
  routes: any;
}

export const PageTabsV2: React.FC<PageTabsV2Props> = ({
  className = '',
  routes,
}) => {
  const location = useLocation();

  const getActiveTab = useCallback(() => {
    return location.pathname.split('/').pop();
  }, [location]);

  const [activeTab, setActiveTab] = useState(getActiveTab());

  useEffect(() => {
    setActiveTab(getActiveTab);
  }, [getActiveTab, location.pathname]);

  const handleTabClick = (path: string = '') => {
    setActiveTab(path);
    History.push(path);
  };

  const visibleTabs = routes.filter(route => route?.tabs !== false);

  return (
    <Tabs className={className}>
      {visibleTabs.map(({ title = '', path }) => {
        // Handle array or string paths
        const isActive = Array.isArray(path)
          ? path.some(p => activeTab === p.split('/').pop())
          : activeTab === (path as string).split('/').pop();

        return (
          <Tab
            key={title}
            value={Array.isArray(path) ? path[0] : path}
            isActive={isActive}
            onSelect={() =>
              handleTabClick(Array.isArray(path) ? path[0] : path)
            }
          >
            {title}
          </Tab>
        );
      })}
    </Tabs>
  );
};
