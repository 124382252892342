export const formatPrice = (
  price: number,
  fractionDigits = 2,
  locale?: string,
) => {
  const convertedPrice = Number(price);

  if (Number.isNaN(convertedPrice)) {
    return 'N/A';
  }

  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits, // to omit the "maximumFractionDigits is out of range" issue
    maximumFractionDigits: fractionDigits,
  }).format(convertedPrice);
};

export const toPercentage = (numerator, denominator) => {
  if (!denominator) return Number(0).toFixed(2);
  return Number((100 * numerator) / denominator).toFixed(2);
};

export const formatDate = (date: Date, config?: any, locale?: string) => {
  const dateConfig = config || {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return date?.toLocaleDateString(locale, dateConfig);
};

export const formatDateTime = (date: Date, config?: any, locale?: string) => {
  const dateConfig = config || {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  };

  return new Intl.DateTimeFormat(locale, dateConfig).format(date);
};

export const intToString = value => {
  const intToStringConfig = [
    {
      value: 1000000000000,
      label: 'T',
    },
    {
      value: 1000000000,
      label: 'B',
    },
    {
      value: 1000000,
      label: 'M',
    },
    {
      value: 1000,
      label: 'K',
    },
  ];

  const intToStringConfigData = intToStringConfig.find(
    ({ value: configValue }) => value > configValue,
  );

  if (!intToStringConfigData) {
    return value;
  }

  const convertedValue = (value / intToStringConfigData.value).toFixed(1);

  return `${convertedValue}${intToStringConfigData.label}`;
};

export const formatNumber = (numberToFormat: number, locale?: string) =>
  numberToFormat?.toLocaleString(locale);

export function formatTimeToUTC(time: string | undefined) {
  if (!time?.endsWith('Z')) {
    return `${time}Z`;
  }

  return time;
}
