import * as Sentry from '@sentry/browser';

import { APP } from '../config/Config';

import rootStore from 'shared/stores/RootStore';

// Config
export const DefaultTimeout = 60000;
const controllerSignals = {};

async function safeFetch(
  urlOrRequest,
  options = {},
  timeout = DefaultTimeout,
  requestId?: string,
) {
  const signalId = requestId || urlOrRequest;
  // Use AbortController to properly close the fetch in case of timeouts,
  // otherwise we end up with unhandled fetch promises
  controllerSignals[signalId]?.abort();
  delete controllerSignals[signalId];

  const timeoutId = setTimeout(() => {
    controllerSignals[signalId]?.abort();
    delete controllerSignals[signalId];
  }, timeout);

  return (async () => {
    controllerSignals[signalId] = new AbortController();
    const fetchOpts = {
      signal: controllerSignals[signalId].signal,
      ...options,
    };
    return fetch(urlOrRequest, fetchOpts)
      .finally(() => {
        clearTimeout(timeoutId);
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          throw new Error('cancelled');
        }

        throw error;
      });
  })();
}

async function getStatsApiResponse(
  subpath,
  payload,
  options = {},
  timeout?: number,
  requestId?: string,
) {
  // Hit Stats API and return the response. Options may override defaults.
  const fetchOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
    ...options,
  };

  const response = await safeFetch(
    `${APP.stats_endpoint}${subpath}`,
    fetchOpts,
    timeout,
    requestId,
  );

  if (response instanceof Error) {
    Sentry.captureException(response.message);
    throw response;
  }

  if (response.status === 401) {
    const { error: errorMessage } = await response.json();

    if (errorMessage === 'USER_LOGGED_OUT') {
      rootStore.logout();
      return {};
    }
  }

  if (!response.ok) {
    Sentry.addBreadcrumb({
      category: 'Stats',
      message: `Stats failed with code ${response.status} and message ${response.statusText}`,
      level: Sentry.Severity.Info,
    });
    const ErrorText = 'Stats query failed';
    Sentry.captureException(ErrorText);

    if (subpath && subpath.includes('rules/bt')) {
      const text = await response.text();
      throw new Error(text);
    }

    throw new Error(ErrorText);
  }

  return response.json();
}

export default getStatsApiResponse;
