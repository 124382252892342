import React from 'react';
import styled from 'styled-components';
import { Color, Text } from '@packages/ui';
import { useTranslation } from 'react-i18next';

import { formatPrice } from 'shared/utils/FormatUtil';
import { ProPlanType } from 'shared/constants';

export interface PriceTagProps {
  className?: string;
  data?: {
    id: string;
    total: number;
    sub_amount: number;
  };
}

const PriceTag: React.FC<PriceTagProps> = ({ className = '', data }) => {
  const { t } = useTranslation('portal');
  const isYearlyPlanSelected = data?.id === ProPlanType.YEARLY;
  const selectedPlanPrice = formatPrice(Number(data?.total), 0);

  if (!data) {
    return null;
  }

  return (
    <Container className={className}>
      <PriceInfo>
        <PriceText variant="h1">
          {formatPrice(Number(data?.sub_amount), 0)}
        </PriceText>
        <Divider variant="default">/</Divider>
        <PeriodText variant="default">{t('Month')}</PeriodText>
      </PriceInfo>
      <Description variant="default">
        {isYearlyPlanSelected
          ? `${t('Billed yearly')} (${selectedPlanPrice})`
          : t('Billed monthly')}
      </Description>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  color: ${Color.grey600};
  font-weight: 600;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    font-size: var(--defaultFontSize);
  }
`;

const PriceText = styled(Text)`
  font-size: 36px;
`;

const Divider = styled(Text)`
  color: #33d1cb;
  margin: 0 5px;
  font-weight: 600;
`;

const PeriodText = styled(Text)`
  font-weight: 600;
`;

const Description = styled(Text)`
  display: flex;
  color: ${Color.grey500};
  font-size: 12px;
`;

export default PriceTag;
