import * as React from 'react';
import * as Sentry from '@sentry/browser';

import ErrorPage from './ErrorPage';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false };

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.configureScope(scope => {
      scope.setExtra('Error Info', errorInfo);
    });

    Sentry.captureException(error);
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorPage>
          Something went wrong on our end, please try again.
        </ErrorPage>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
