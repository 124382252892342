import { Line } from '@packages/ui';

export interface LineConfig {
  id: string;
  color: string;
  label: string;
  data: object[];
}

const createDataTemplate = (
  timeStart: number,
  timeEnd: number,
  timeGranularity: number,
  lineConfigs: LineConfig[],
  timestampProperty: string,
): Map<number, number> => {
  const template = new Map<number, number>();

  const granularity = Math.floor(timeGranularity / 60000) * 60000;
  const firstItem = lineConfigs.find(({ data }) => data.length > 0)?.data[0];

  const firstTimestamp = firstItem?.[timestampProperty];
  const offset = firstTimestamp
    ? new Date(firstTimestamp).getTime() % granularity
    : 0;

  const templateStart =
    Math.floor(timeStart / granularity) * granularity + offset;

  for (let current = templateStart; current < timeEnd; current += granularity) {
    template.set(current, 0);
  }

  return template;
};

export const useLineGraphConfigs = (
  timeStart: number,
  timeEnd: number,
  timeGranularity: number,
  lineConfigs: LineConfig[],
  xProperty: string,
  yProperty: string,
) => {
  const dataTemplate = createDataTemplate(
    timeStart,
    timeEnd,
    timeGranularity,
    lineConfigs,
    xProperty,
  );

  const lines: Line[] = lineConfigs.map(({ id, color, label, data }) => {
    const dataMap = new Map(dataTemplate);

    const line: Line = {
      id,
      color,
      points: [],
      label,
    };

    data.forEach(item => {
      const itemDateString = new Date(item?.[xProperty] || '').toISOString();

      const date =
        typeof item[xProperty] === 'number'
          ? item[xProperty]
          : new Date(itemDateString).getTime();
      dataMap.set(date, item[yProperty] || 0);
    });

    dataMap.forEach((yValue, date) => {
      line.points.push({
        id: date.toString(),
        x: date,
        y: yValue,
      });
    });

    line.points.sort(({ x: a }, { x: b }) => a - b);

    return line;
  });

  return lines;
};
