// @flow
import { Color as ColorPalette } from './primitives';

declare const window: any;

const {
  env,
  endpoint,
  stats_endpoint,
  captcha_sitekey,
  passive_captcha_sitekey,
  fraud_billing_captcha_sitekey,
  captcha_endpoint,
  branding,
} = window.APP;

export const APP = {
  env,
  endpoint,
  stats_endpoint,
  captcha_sitekey,
  passive_captcha_sitekey,
  fraud_billing_captcha_sitekey,
  captcha_endpoint,
  branding,
  accounts_captcha_endpoint: 'https://accounts.hcaptcha.com',
};

export const hCaptcha = {
  sitekey: APP.captcha_sitekey,
  passiveSitekey: APP.passive_captcha_sitekey,
  fraudBillingCaptchaSitekey: APP.fraud_billing_captcha_sitekey,
  endpoint: APP.captcha_endpoint,
};

export const ColorConfig = {
  black: {
    default: '#000',
  },

  link: {
    default: '#33d1cb',
  },

  purple: {
    default: '#6549c7',
    hover: '#483499',
    deep: '#291e4b',
    accent: '#8376ab',
  },

  darkPurple: {
    default: '#555166',
    hover: '#474455',
  },

  green: {
    default: '#006075',
    hover: '#008f8b',
  },

  blue: {
    default: '#4267b2',
    hover: '#375695',
  },

  lightBlue: {
    default: '#1ca1f2',
    hover: '#0d8bd9',
  },

  red: {
    default: '#d65050',
    hover: '#bb2e2e',
  },

  turquoise: {
    default: '#006075',
    hover: '#008a85',
    active: '#4de1d2',
  },

  grey: {
    default: '#e6e6e6',
    dark: '#222222',
    hover: '#cccccc',
    light: '#f1f2f4',
  },

  grey050: ColorPalette.grey050,
  grey100: ColorPalette.grey100,
  grey200: ColorPalette.grey200,
  grey300: ColorPalette.grey300,
  grey400: ColorPalette.grey400,
  grey500: ColorPalette.grey500,
  grey600: ColorPalette.grey600,
  grey700: ColorPalette.grey700,
  grey800: ColorPalette.grey800,
  grey900: ColorPalette.grey900,
  grey1000: ColorPalette.grey1000,

  yellow300: ColorPalette.yellow200,
  yellow400: ColorPalette.yellow300,
  yellow800: ColorPalette.yellow700,

  red200: ColorPalette.red100,
  red400: ColorPalette.red300,
  red500: ColorPalette.red400,
  red600: ColorPalette.red500,
  red700: ColorPalette.red600,

  green200: ColorPalette.green100,
  green300: ColorPalette.green200,
  green400: ColorPalette.green300,
  green500: ColorPalette.green400,
  green600: ColorPalette.green500,
  green700: ColorPalette.green600,

  blue200: ColorPalette.blue100,
  blue300: ColorPalette.blue200,
  blue400: ColorPalette.blue300,
  blue700: ColorPalette.blue600,

  purple200: ColorPalette.purple100,
  purple300: ColorPalette.purple200,
  purple400: ColorPalette.purple300,
  purple700: ColorPalette.purple600,

  teal200: ColorPalette.green100,
  teal300: ColorPalette.green200,
  teal700: ColorPalette.green600,

  orange300: ColorPalette.orange200,
  orange600: ColorPalette.orange500,
  orange800: ColorPalette.orange700,

  darkGrey: {
    default: '#808080',
    dark: '#6e829e',
    hover: '#666666',
  },

  orange: {
    default: '#ff6d00',
    hover: '#cc5800',
  },

  white: {
    default: '#fff',
    hover: '#f0f2F4',
  },

  tabs: {
    selected: {
      default: '#706499',
      hover: '#665b8b',
    },
    hover: '#9b97b5',
  },
};

export const AnnotationTypes = [
  { value: 'boundingBox', label: 'Bounding box' },
  { value: 'categorization', label: 'Categorization' },
  { value: 'comparison', label: 'Comparison' },
  { value: 'landmark', label: 'Landmark' },
  { value: 'polygon3', label: 'Polygon 3 points' },
  { value: 'polygon4', label: 'Polygon 4 points' },
  { value: 'polygon5', label: 'Polygon 5 points' },
  { value: 'polygon6', label: 'Polygon 6 points' },
];
