const {
  env,
  endpoint,
  stats_endpoint,
  captcha_sitekey,
  passive_captcha_sitekey,
  fraud_billing_captcha_sitekey,
  captcha_endpoint,
  branding,
  yellow_endpoint,
  yellow_stats_endpoint,
  docs_url,
} = window.APP;
export const APP = {
  env,
  endpoint: process.env.USE_YELLOW_API ? yellow_endpoint : endpoint,
  stats_endpoint: process.env.USE_YELLOW_API
    ? yellow_stats_endpoint
    : stats_endpoint,
  captcha_sitekey,
  passive_captcha_sitekey,
  fraud_billing_captcha_sitekey,
  captcha_endpoint,
  branding,
  accounts_captcha_endpoint: 'https://accounts.hcaptcha.com',
  docs_url,
};
export const IS_DASHBOARD_PROD_HCAPTCHA =
  window.location.hostname === 'dashboard.hcaptcha.com';
export const IS_DASHBOARD_YELLOW_HCAPTCHA =
  window.location.hostname === 'yellowdash.hmt.ai';
export const IS_LOCAL_ENV = APP.env === 'development';
export const IS_PROD_ENV = APP.env === 'production';
export const hCaptcha = {
  sitekey: APP.captcha_sitekey,
  passiveSitekey: APP.passive_captcha_sitekey,
  fraudBillingCaptchaSitekey: APP.fraud_billing_captcha_sitekey,
  endpoint: APP.captcha_endpoint,
};
export const AnnotationTypes = [
  {
    value: 'boundingBox',
    label: 'Bounding box',
  },
  {
    value: 'categorization',
    label: 'Categorization',
  },
  {
    value: 'comparison',
    label: 'Comparison',
  },
  {
    value: 'landmark',
    label: 'Landmark',
  },
  {
    value: 'polygon3',
    label: 'Polygon 3 points',
  },
  {
    value: 'polygon4',
    label: 'Polygon 4 points',
  },
  {
    value: 'polygon5',
    label: 'Polygon 5 points',
  },
  {
    value: 'polygon6',
    label: 'Polygon 6 points',
  },
];
export const IS_FOUNDATION = APP.branding === 'foundation';
export const BRANDING = IS_FOUNDATION ? 'foundation' : 'hCaptcha';
export const COMPANY = IS_FOUNDATION
  ? 'Human Protocol Foundation'
  : 'Intuition Machines, Inc';
export const TEXT_BRANDING = IS_FOUNDATION ? 'HUMAN Protocol' : 'hCaptcha';
export const LINK_BRANDING = IS_FOUNDATION
  ? 'https://www.humanprotocol.org/'
  : 'https://www.hcaptcha.com';
export const LINK_COMPANY = IS_FOUNDATION
  ? 'https://www.humanprotocol.org/privacy-policy'
  : 'https://www.hcaptcha.com/privacy';
// Message thrown by backend indicating session expiration
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
