import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export function useQueryParam() {
  const location = useLocation();
  const { search } = location;

  window.history.replaceState({}, document.title);

  return useMemo(() => new URLSearchParams(search), [search]);
}
