import { SortDirection } from '../constants';

/* eslint-disable import/prefer-default-export */
export const findDifferentObjectValues = (
  originalObject: object,
  modifiedObject: object,
) => {
  const diff = {};

  const keys = new Set([
    ...Object.keys(originalObject),
    ...Object.keys(modifiedObject),
  ]);

  for (const key of keys) {
    if (
      JSON.stringify(originalObject[key]) !==
      JSON.stringify(modifiedObject[key])
    ) {
      diff[key] = modifiedObject[key];
    }
  }

  return diff;
};

export const getSortedObjectArray = (
  items: any[],
  property: string,
  order: SortDirection = SortDirection.ASC,
) =>
  items.sort((a, b) => {
    // asc or desc, null values are always sorted after
    if (a[property] === null) {
      return 1;
    }

    if (b[property] === null) {
      return -1;
    }

    if (a[property] < b[property]) {
      return order === 'asc' ? -1 : 1;
    }

    if (a[property] > b[property]) {
      return order === 'asc' ? 1 : -1;
    }

    return 0;
  });
