// i18n NPM Packages
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
// import Logger from './models/Logger';
// Translation ISO-Codes and Language
import Languages from '@hcaptcha/dashboard-i18n/src/languages.json';

const ISOCodes = Object.keys(Languages);
const detectionOptions = {
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'navigator'],
  // keys or params to lookup language from
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  // cache user language on
  caches: [],
  excludeCacheFor: ['cimode', 'localStorage', 'cookie'],
  // languages to not persist (cookie, localStorage)
  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: {
    path: '/',
  },
};
i18n
  .use(Detector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    saveMissing: process.env.NODE_ENV !== 'production',
    missingKeyHandler: (
      lngs: string[],
      ns: string,
      key: string,
      fallbackValue: string,
    ): void => {
      // Logger.error(
      //   `Missed translation key: ${JSON.stringify({
      //     ns,
      //     key,
      //     fallbackValue,
      //   })}`,
      // );
    },
    detection: detectionOptions,
    load: 'languageOnly',
    fallbackLng: 'en',
    // use en if detected lng is not available
    keySeparator: '::',
    nsSeparator: '::',
    supportedLngs: ISOCodes,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: [
      // List of translation files to use
      'generic',
      'jobs',
      'overview',
      'portal',
      'settings',
      'sites',
      'support',
      'welcome',
    ],
    defaultNS: 'generic', // Default translation file to lookup if none is specified
  });
i18n.on('languageChanged', () => {
  document.documentElement.lang = i18n.language;
});
export default i18n;
export { Languages };
