import { makeAutoObservable } from 'mobx';

import { ISubscriptionApi, SubscriptionPlan } from 'shared/api/subscriptionApi';

enum StripeIntent {
  PAYMENT_INTENT_ID = 'pi_',
  CARD_SETUP_INTENT_ID = 'seti_',
}

export default class SubscriptionStore {
  transport: ISubscriptionApi;

  subscription: {
    clientSecret: {
      pro_publisher_yearly_plan?: string;
      pro_publisher_monthly_plan?: string;
    };
    selectedPlan: string;
    id: string;
    isLoading: boolean;
  };

  subscriptionPlans: SubscriptionPlan[];

  billingDetails: {
    plan: any;
    isStripeIntegrated?: boolean;
    isOnFreeTrial?: boolean;
    isFreeTrialEligible?: boolean;
    currentCycleUsage?: number;
    currentCycleStart?: string;
    currentCycleEnd?: string;
  };

  billingHistory?: any;

  constructor(transport: ISubscriptionApi) {
    this.transport = transport;
    this.subscription = {
      id: '',
      clientSecret: {},
      isLoading: false,
      selectedPlan: '',
    };
    makeAutoObservable(this);
  }

  get subscriptionId() {
    return this.subscription.id;
  }

  get clientSecret() {
    return this.subscription.clientSecret;
  }

  get subscriptionClientSecret() {
    return this.subscription.id
      ? this.subscription.clientSecret[this.subscription.id]
      : '';
  }

  get isPaymentIntent() {
    return this.subscriptionClientSecret.startsWith(
      StripeIntent.PAYMENT_INTENT_ID,
    );
  }

  get isCardSetupIntent() {
    return this.subscriptionClientSecret.startsWith(
      StripeIntent.CARD_SETUP_INTENT_ID,
    );
  }

  get selectedPlan(): string {
    return this.subscription.selectedPlan || '';
  }

  setSelectedPlan(value: string) {
    this.subscription.selectedPlan = value;
  }

  get isLoading(): boolean {
    return this.subscription.isLoading;
  }

  setIsLoading(value: boolean) {
    this.subscription.isLoading = value;
  }

  async fetchSubscriptionPlans() {
    if (!this.subscriptionPlans?.length) {
      this.subscriptionPlans = await this.transport.getSubscriptionPlans();
    }

    return this.subscriptionPlans;
  }

  async fetchBillingDetails() {
    if (!this.billingDetails) {
      const billingData = await this.transport.fetchBillingDetails();

      this.setBillingDetails(billingData);
    }

    return this.billingDetails;
  }

  async fetchBillingHistory() {
    const {
      data: { results = [] },
    } = await this.transport.fetchBillingHistory();
    this.billingHistory = results;

    return this.billingHistory;
  }

  async isUserPro() {
    const { is_pro: isPro } = await this.transport.pollForRole();
    return isPro;
  }

  async proceedSubscription(
    planId: string,
    isFreeTrial: boolean,
    couponCode: string = '',
    captchaToken: string = '',
  ) {
    const { client_secret } = await this.transport.proceedSubscription(
      planId,
      isFreeTrial,
      couponCode,
      captchaToken,
    );
    this.subscription.id = planId;
    this.subscription.clientSecret = {
      ...this.subscription.clientSecret,
      [planId]: client_secret,
    };
    return this.subscription.clientSecret[planId];
  }

  async validateCouponCode(couponCode: string) {
    const {
      valid: isValid,
      amount_off: amountOff,
      percent_off: discountPercentage,
    } = await this.transport.validateCouponCode(couponCode);
    return {
      isValid,
      amountOff,
      discountPercentage,
    };
  }

  setBillingDetails(billingData) {
    this.billingDetails = {
      plan: billingData?.data?.plan ?? {},
      isStripeIntegrated: !!billingData?.has_stripe,
      isOnFreeTrial: !!billingData?.on_trial,
      isFreeTrialEligible: !!billingData?.is_trial_eligible,
      currentCycleUsage: billingData?.current_cycle_usage,
      currentCycleStart: billingData?.current_cycle_start,
      currentCycleEnd: billingData?.current_cycle_end,
    };
  }

  async cancelSubscription() {
    const billingData = await this.transport.cancelSubscription();

    this.subscription.id = '';
    this.subscription.clientSecret = {};
    this.setBillingDetails(billingData);

    return this.billingDetails;
  }

  async reactivateSubscription() {
    const billingData = await this.transport.reactivateSubscription();

    this.subscription.id = '';
    this.subscription.clientSecret = {};
    this.setBillingDetails(billingData);

    return this.billingDetails;
  }
}
