import React, { useState, memo } from 'react';
import styled from 'styled-components';
import { Size } from '@packages/ui';

import { Border } from '../Border';
import { Input } from '../Input';

export interface TextInputProps {
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  height?: number;
  placeholder?: string;
  value?: string;
  label?: string;
  pattern?: string;
  disabled?: boolean;
  maxLength?: number;
  className?: string;
  tabIndex?: string;
  textAlign?: string;
  onChange: (value: string) => void;
  onKeyDown?: (evt: KeyboardEvent) => void;
  onFocus?: () => void;
  onBlur?: () => Promise<void> | void;
  type?: string;
  isValid?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  ariaRequired?: boolean;
  defaultBorderColor?: string; // quick hack for a11y, don't use it
}

const TextInput: React.FC<TextInputProps> = ({
  width,
  maxWidth,
  minWidth,
  height = 40,
  placeholder = '',
  value = '',
  disabled = false,
  className,
  maxLength = null,
  tabIndex = '0',
  pattern,
  onChange,
  onKeyDown,
  textAlign,
  type,
  isValid = true,
  label = '',
  autoFocus,
  ariaRequired,
  autoComplete,
  defaultBorderColor,
  onFocus,
  onBlur,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleFocus = () => {
    setIsFocused(true);
    onFocus?.();
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };

  return (
    <StyledBorder
      color={!isValid ? 'red' : isFocused ? 'grey' : defaultBorderColor}
      dataCy={`input-${label}`}
      width={width}
      height={height}
      className={className}
    >
      <Input
        label={label}
        ariaRequired={ariaRequired}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        type={type}
        maxWidth={maxWidth}
        width={width}
        minWidth={minWidth}
        height={height}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        pattern={pattern}
        maxLength={maxLength}
        tabIndex={tabIndex}
        onChange={onChange}
        onKeyDown={onKeyDown}
        textAlign={textAlign}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </StyledBorder>
  );
};

const StyledBorder = styled(Border)`
  overflow: hidden;
  width: ${props => (props.width ? `${props.width}px` : '100%')};

  @media (max-width: ${Size.mobile}) {
    max-width: 100%;
  }
`;

export default memo(TextInput);
