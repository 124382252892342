import Api from '.';

export type SubscriptionPlan = {
  id: string;
  sub_amount: number | string;
  total: number | string;
  price: string;
  pricePeriod: string;
  name: string;
  description: string;
};

const getSubscriptionPlans = async () => {
  const {
    data: { results = [] },
  } = await Api.fetchBillingPlans();

  return results;
};

const fetchBillingHistory = async () => {
  const data = await Api.fetchBillingHistory();

  return data;
};

const pollForRole = async () => {
  const { data: billingData } = await Api.pollForRole();
  return billingData;
};

const fetchBillingDetails = async () => {
  const { data: billingData } = await Api.fetchBillingDetails();

  return billingData;
};

const cancelSubscription = async () => {
  const response = await Api.cancelSubscription();

  return response.data;
};

const reactivateSubscription = async () => {
  const response = await Api.reactivateSubscription();

  return response.data;
};

const proceedSubscription = async (
  planId: string,
  isFreeTrial: boolean,
  couponCode: string = '',
  captchaToken: string = '',
) => {
  const response = await Api.postBillingPay({
    plan_id: planId,
    is_free_trial: isFreeTrial,
    coupon_code: couponCode,
    token: captchaToken,
  });
  return response.data;
};

const validateCouponCode = async (couponCode: string) => {
  const response = await Api.validateCouponCode(couponCode);
  return response.data;
};

const SubscriptionApi = {
  pollForRole,
  validateCouponCode,
  proceedSubscription,
  getSubscriptionPlans,
  fetchBillingDetails,
  fetchBillingHistory,
  cancelSubscription,
  reactivateSubscription,
};

export type ISubscriptionApi = typeof SubscriptionApi;
export default SubscriptionApi;
