import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Loader, useToast } from '@packages/ui';

import {
  AllowedAccount,
  AccountSection,
  FavoriteAction,
} from '../../data/types/AllowedAccount';
import { Api } from '../../data/api';

import AccountSearch from './AccountSearch';
import AccountsSection from './AccountsSection';
import { MessageContainer } from './MessageContainer';
import { MessageTitle } from './MessageTitle';
import { MessageBody } from './MessageBody';
import { MessageCtaButton } from './MessageCtaButton';
import { ContactSupportLink } from './ContactSupportLink';

import Logger from 'shared/models/Logger';

interface AccountSelectProps {
  availableAccounts: AllowedAccount[];
  onAccountSelect: () => void;
  isPageView?: boolean;
}

export default function AccountSelect({
  availableAccounts,
  onAccountSelect,
  isPageView,
}: AccountSelectProps): React.ReactElement {
  const { t } = useTranslation(['generic', 'portal']);
  const toast = useToast();

  const [accountsState, setAccountsState] = useState({
    available: availableAccounts,
    displayed: availableAccounts,
  });

  const [searchState, setSearchState] = useState({
    isLoading: false,
    isError: false,
    failedSearchTerm: '',
  });

  const handleSearch = async (term: string) => {
    try {
      if (term === '') {
        return;
      }

      setSearchState({ ...searchState, isLoading: true });

      const request = await Api.searchAccount(term);
      const {
        data,
      }: { data: { available_accounts: AllowedAccount[] } } = request;

      setAccountsState({
        ...accountsState,
        displayed: data.available_accounts || [],
      });
    } catch (err) {
      Logger.error(`Could not perform allowed_accounts query: ${err}`);
      setSearchState({ ...searchState, isError: true, failedSearchTerm: term });
    } finally {
      setSearchState({ ...searchState, isLoading: false });
    }
  };

  const handleClear = () => {
    setAccountsState({ ...accountsState, displayed: accountsState.available });
  };

  const handleAccountSelect = async (account: AllowedAccount) => {
    onAccountSelect();

    try {
      await Api.switchUser(account.id);
      window.location.assign('/');
    } catch (err) {
      Logger.error(`couldn't switch to selected account: ${err}`);
      toast.error(t('Could not switch to selected account, please try again'));

      onAccountSelect();
    }
  };

  const handleFavorite = (target: AllowedAccount, action: FavoriteAction) => {
    const found = accountsState.available.find(
      account => account.email === target.email,
    );

    if (found) {
      const mutated: AllowedAccount = {
        ...found,
        is_favorite: action === FavoriteAction.FAVORITE,
      };

      setAccountsState({
        available: [
          ...accountsState.available.filter(
            account => account.email !== target.email,
          ),
          mutated,
        ],
        displayed: [
          ...accountsState.displayed.filter(
            account => account.email !== target.email,
          ),
          mutated,
        ],
      });
    }
  };

  const handleSearchRetry = () => {
    setSearchState({ ...searchState, isError: false });
    handleSearch(searchState.failedSearchTerm);
  };

  return (
    <>
      <AccountSearch onSearch={handleSearch} onClear={handleClear} />
      {searchState.isLoading && <Loader />}
      {searchState.isError && (
        <MessageContainer>
          <MessageTitle>{t('Something went wrong')}</MessageTitle>
          <Trans
            t={t}
            i18nKey="<0>Sorry for the inconvenience, something went wrong. Try to reload the page or <1>contact our support team</1>.</0>"
          >
            <MessageBody>
              Sorry for the inconvenience, something went wrong. Retry the
              search submission or{' '}
              <ContactSupportLink href="mailto: support@hcaptcha.com">
                contact our support team
              </ContactSupportLink>
              .
            </MessageBody>
          </Trans>
          <MessageCtaButton onAction={handleSearchRetry}>
            {t('Retry Search')}
          </MessageCtaButton>
        </MessageContainer>
      )}
      {!accountsState.displayed.length && (
        <MessageContainer>
          <MessageTitle>{t('No results found')}</MessageTitle>
          <MessageBody>
            {t(
              'Unfortunately, we couldn’t find any accounts that match the information you provided.',
            )}
          </MessageBody>
          <MessageBody>
            {t('Please double-check your search criteria and try again.')}
          </MessageBody>
        </MessageContainer>
      )}
      {!searchState.isLoading &&
        !searchState.isError &&
        accountsState.displayed.length > 0 && (
          <>
            <AccountsSection
              type={AccountSection.OWNER}
              title={t('Owner')}
              accounts={accountsState.displayed}
              onSelect={handleAccountSelect}
              onFavorite={handleFavorite}
              isPageView={isPageView}
            />
            <AccountsSection
              type={AccountSection.FAVORITE}
              title={t('Favorites')}
              accounts={accountsState.displayed}
              onSelect={handleAccountSelect}
              onFavorite={handleFavorite}
              isPageView={isPageView}
            />
            <AccountsSection
              type={AccountSection.ZONE}
              title={t('Zones')}
              accounts={accountsState.displayed}
              onSelect={handleAccountSelect}
              onFavorite={handleFavorite}
              isPageView={isPageView}
            />
            <AccountsSection
              type={AccountSection.SHARED}
              title={t('Shared')}
              accounts={accountsState.displayed}
              onSelect={handleAccountSelect}
              onFavorite={handleFavorite}
              isPageView={isPageView}
            />
          </>
        )}
    </>
  );
}
