import * as Sentry from '@sentry/browser';

import { SentryError } from './types';

const baseError = {
  level: Sentry.Severity.Error,
  category: 'fetch',
};

const sentryErrorList: Record<number, SentryError> = {
  400: {
    ...baseError,
    message:
      'Bad Request: The server could not understand the request due to invalid syntax.',
  },
  401: {
    ...baseError,
    message: 'Unauthorized: Attempted access by an unauthorized user.',
  },
  403: {
    ...baseError,
    message:
      'Forbidden: The client does not have access rights to the content.',
  },
  404: {
    ...baseError,
    message: 'Not Found: The server can not find the requested resource.',
  },
  408: {
    ...baseError,
    message: 'Request Timeout: The server timed out waiting for the request.',
  },
  429: {
    ...baseError,
    message:
      'Too Many Requests: The user has sent too many requests in a given amount of time.',
  },
  500: {
    ...baseError,
    message:
      'Internal Server Error: The server has encountered an issue and it does not know how to handle it.',
  },
  502: {
    ...baseError,
    message:
      'Bad Gateway: The server received an invalid response from the server.',
  },
  503: {
    ...baseError,
    message:
      'Service Unavailable: The server is not ready to handle the request.',
  },
  504: {
    ...baseError,
    message:
      'Gateway Timeout: The server did not receive a timely response server.',
  },
};

/**
 * Function that maps an HTTP status code and relates it to a SentryError object.
 *
 * @param {number} status - The HTTP status code.
 * @param {any} data - Additional data to include with the error.
 * @returns {SentryError} - The corresponding SentryError object.
 */
export function mapSentryError(status: number, data: any): SentryError {
  const defaultError = {
    ...baseError,
    message: `An error occurred with status code ${status}.`,
    data,
  };

  return sentryErrorList[status]
    ? { ...sentryErrorList[status], data }
    : defaultError;
}
