import React from 'react';
import styled from 'styled-components';
import { Loader } from '@packages/ui';

export interface ContentLoaderProps {
  className?: string;
  dataTestId?: string;
}

export const ContentLoader: React.FC<ContentLoaderProps> = ({
  className,
  dataTestId,
}) => {
  return <Container size="s" className={className} dataTestId={dataTestId} />;
};

const Container = styled(Loader)`
  padding: 48px 0 0;
  height: auto;

  @media (min-width: 738px) {
    padding: 112px 0 0;
  }
`;

export default ContentLoader;
