import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from '@packages/ui';

import { formatNumber } from 'shared/utils/FormatUtil';

export interface PlanDescriptionProps {
  className?: string;
}

const PlanDescription: React.FC<PlanDescriptionProps> = ({
  className = '',
}) => {
  const { t } = useTranslation('portal');
  const proPlanRequestsCount = 100000;

  return (
    <Container className={className}>
      <ContainerText variant="default">
        {t(
          `hCaptcha Pro improves your users' experience with low-friction modes, traffic insights, and more.`,
        )}
      </ContainerText>
      <List>
        <ListItem>
          <ContainerText variant="default">
            {t(
              `Reduce user friction with 99.9% passive modes: let lower-risk traffic skip a visual challenge.`,
            )}
          </ContainerText>
        </ListItem>
        <ListItem>
          <ContainerText variant="default">
            {t(
              `Traffic insights give you detailed breakdowns of service usage.`,
            )}
          </ContainerText>
        </ListItem>
        <ListItem>
          <ContainerText variant="default">
            {t(`{{requestsCount}} requests per month included.`, {
              requestsCount: formatNumber(proPlanRequestsCount),
            })}
          </ContainerText>
        </ListItem>
      </List>
    </Container>
  );
};

const Container = styled.div``;

const ContainerText = styled(Text)``;

const List = styled.ul`
  padding-left: 20px;
  margin: 24px 0 0;
`;

const ListItem = styled.li`
  & + & {
    margin-top: 16px;
  }
`;

export default PlanDescription;
