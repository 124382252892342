/**
 * Formats the request body received, based on the content type.
 *
 * @param {any} data - The data to format.
 * @param {string} contentType - The content type for formatting.
 * @returns {any} - The formatted request body.
 * @throws {Error} - Throws an error if the data is not an object and content type is application/json.
 */
export function formatRequestBody(data: any, contentType: string): any {
  if (data === undefined) {
    return undefined;
  }

  if (
    contentType === 'application/json' &&
    (typeof data !== 'object' || data === null)
  ) {
    throw new Error('Data must be an object for application/json content type');
  }

  if (!data || typeof data !== 'object') return data.toString();

  switch (contentType) {
    case 'application/json':
      return JSON.stringify(data);
    case 'application/x-www-form-urlencoded':
      return new URLSearchParams(data).toString();
    default:
      return data.toString();
  }
}
