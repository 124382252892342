import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Color, Text, Button, Icon } from '@packages/ui';

import History from 'shared/models/History';

interface BackButtonProps {
  className?: string;
  path?: string;
  title?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ className, path, title }) => {
  const { t } = useTranslation('generic');

  const handleClick = () => {
    if (path) {
      History.push('/fraud/rules');
    }

    if (History.location.key) {
      History.goBack();
    }
  };

  if (!path && !History.location.key) {
    return null;
  }

  return (
    <Container className={className} variant="link" onClick={handleClick}>
      <LeftChevronIcon icon="chevronRight" iconSize="14" />
      <Text>{title || t('Back')}</Text>
    </Container>
  );
};

export const Container = styled(Button)`
  display: inline-flex;
  align-items: center;
  padding: 0;
  color: ${Color.grey600};
`;

export const LeftChevronIcon = styled(Icon)`
  transform: rotate(180deg);
  display: inline-block;
  margin-right: 4px;
  pointer-events: none;
`;

export default BackButton;
