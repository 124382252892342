import React, { ReactNode } from 'react';
import styled from 'styled-components';

// helpers
import { BRANDING } from '../config/Config';

import { LOGO_FULL } from './logo/logo-helpers';

type ErrorPageProps = {
  children: ReactNode;
};

const ErrorPage = ({ children }: ErrorPageProps) => {
  return (
    <ErrorPageContainer>
      <img src={LOGO_FULL} alt={BRANDING} />
      <Text>{children}</Text>
    </ErrorPageContainer>
  );
};

const ErrorPageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: inherit;
`;
const Text = styled.div`
  margin-top: 20px;
  text-align: center;
`;
export default ErrorPage;
