import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Copyright } from '../Copyright';
import { Legal } from '../Legal';

const PageFooter = () => {
  const { t, i18n } = useTranslation('portal');

  const [isLangBlockVisible, setIsLangBlockVisible] = useState<boolean>(
    i18n.language.substring(0, 2) !== 'en',
  );

  const switchToEn = () => {
    i18n.changeLanguage('en');
    setIsLangBlockVisible(false);
  };

  return (
    <Wrapper>
      <Legal />
      <Copyright />
      {isLangBlockVisible && (
        <LangSwitchBlock
          data-cy="switchToEn"
          aria-label={t('Switch page language to English')}
          onClick={switchToEn}
          tabIndex="0"
        >
          {t('Switch to English')}
        </LangSwitchBlock>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 20px;

  color: #555555;
  text-align: center;
  font-size: 10px;
  font-weight: 500;

  margin: 0 auto;

  position: relative;
`;

const LangSwitchBlock = styled.div`
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;

  color: #007cbf;
  font-size: 10px;
  font-weight: 300;

  :hover {
    color: #006196;
  }
`;

export default PageFooter;
