import React, { useState, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CardItem, Color, Icon, Size } from '@packages/ui';

import {
  AllowedAccount,
  AccountType,
  FavoriteAction,
} from 'main/data/types/AllowedAccount';

interface AccountCardProps {
  id: string;
  account: AllowedAccount;
  onFavorite: (
    action: 'remove' | 'favorite',
    account: AllowedAccount,
  ) => Promise<void>;
  onSelect: (account: AllowedAccount) => Promise<void>;
  isPageView?: boolean;
}

export default function AccountCard({
  id,
  account,
  onFavorite,
  onSelect,
  isPageView,
}: AccountCardProps): React.ReactElement {
  const { t } = useTranslation('generic');
  const [isIconFocused, setIsIconFocused] = useState(false);

  const { email, is_favorite: isFavorite, account_type: accountType } = account;
  const isOwner = accountType === AccountType.OWNER;

  const splitEmailUser = email.split('@')[0].split('+');

  const cardTitle = useMemo(() => {
    if (email.includes('+')) {
      return splitEmailUser.slice(0, splitEmailUser.length - 1).join(' ');
    }

    return splitEmailUser.join(' ');
  }, [email, splitEmailUser]);

  const cardLabel = useMemo(() => {
    if (accountType === AccountType.REGULAR) {
      return t('shared');
    }

    if (email.includes('+')) {
      return splitEmailUser[splitEmailUser.length - 1];
    }

    return splitEmailUser.join('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType, email]);

  const handleFavorite = async () => {
    await onFavorite?.(
      isFavorite ? FavoriteAction.REMOVE : FavoriteAction.FAVORITE,
      account,
    );
  };

  const handleIconFocus = () => {
    setIsIconFocused(true);
  };

  const handleIconBlur = () => {
    setIsIconFocused(false);
  };

  const handleSelect = () => {
    onSelect(account);
  };

  return (
    <StyledCardItem
      id={id}
      title={cardTitle}
      text={account.email}
      avatar={isOwner && <Avatar>{account.email[0]}</Avatar>}
      label={isOwner ? undefined : cardLabel}
      labelVariant={
        isOwner
          ? undefined
          : account.account_type === AccountType.ZONE
          ? 'blue'
          : 'purple'
      }
      icon={
        isOwner ? (
          undefined
        ) : (
          <FavoriteIcon
            icon={isFavorite ? 'favoriteSelected' : 'favoriteUnselected'}
            iconSize="16"
            isFavorite={isFavorite}
            isFocused={isIconFocused}
          />
        )
      }
      onIconInteraction={isOwner ? undefined : handleFavorite}
      onIconFocus={isOwner ? undefined : handleIconFocus}
      onIconBlur={isOwner ? undefined : handleIconBlur}
      onSelect={handleSelect}
      isPageView={isPageView}
      isOwner={isOwner}
    />
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const StyledCardItem = styled(CardItem)<{
  isPageView?: boolean;
  isOwner?: boolean;
}>`
  width: ${({ isPageView, isOwner }) =>
    isPageView && !isOwner ? 'calc(50% - 8px)' : '100%'};
  height: 74px;

  animation: ${fadeIn} 0.4s linear;

  @media (max-width: ${Size.mobile}) {
    width: 100%;
    max-width: unset;
    float: unset;
  }
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: ${Color.grey600};

  font-size: 12px;
  font-weight: 500;
  color: ${Color.white};
  text-transform: uppercase;
`;

const FavoriteIcon = styled(Icon)<{
  isFavorite: boolean;
  isFocused: boolean;
}>`
  path {
    fill: ${({ isFavorite, isFocused, isLoading }) => {
      if (isLoading) {
        return Color.grey300;
      }

      if (isFavorite && isFocused) {
        return Color.yellow400;
      }

      if (isFavorite) {
        return Color.yellow300;
      }

      return 'transparent';
    }};
    stroke: ${({ isFavorite, isFocused }) => {
      if (isFavorite && isFocused) {
        return Color.yellow400;
      }

      if (isFavorite) {
        return Color.yellow300;
      }

      if (isFocused) {
        return Color.grey400;
      }

      return Color.grey200;
    }};
  }

  &:hover {
    path {
      fill: ${({ isFavorite }) =>
        isFavorite ? Color.yellow400 : 'transparent'};
      stroke: ${({ isFavorite }) =>
        isFavorite ? Color.yellow400 : Color.grey400};
    }
  }
`;
