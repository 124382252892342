import React from 'react';
import styled from 'styled-components';
import { Color } from '@packages/ui';

export interface SectionHeaderProps {
  children: React.ReactNode;
  errorMsg?: string;
  className?: string;
  ['data-cy']: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = props => {
  const { className = '', children, errorMsg } = props;

  return (
    <Wrapper className={className} data-cy={props['data-cy']}>
      {errorMsg && (
        <ErrorMessage>Error while saving sitekey: {errorMsg}</ErrorMessage>
      )}
      <Title>{children}</Title>
      <Underline />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: auto;

  position: relative;
  margin-bottom: 30px;
`;

const Title = styled.div`
  user-select: none;

  font-size: 18px;
  font-weight: 600;
  line-height: 21px;

  color: ${Color.grey600};
`;

const Underline = styled.div`
  width: 100%;
  height: 1px;

  margin-top: 10px;

  background-color: #33d1cb;
`;

const ErrorMessage = styled.span`
  color: ${Color.red500};
  margin-bottom: 10px;
  display: inline-block;
`;

export default SectionHeader;
