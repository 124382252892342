import React, { ReactNode, ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';

import History from 'shared/models/History';

type Props = {
  isAuthenticated: boolean;
  component: ComponentType<ReactNode>;
  routes: Array<any>;
  exact: true;
  path: string;
};

const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  routes,
  exact,
  path,
}: Props) => (
  <Route
    exact={exact}
    path={path}
    render={props =>
      isAuthenticated ? ( // eslint-disable-next-line react/jsx-props-no-spreading
        <Component routes={routes} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: History.location.pathname,
            },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
