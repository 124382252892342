import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Color, Icon, IconProp, Text, Panel } from '@packages/ui';

import { handleEnterKeyDown } from 'shared/utils';

export interface OptionPanelProps {
  className?: string;
  title?: string;
  icon?: IconProp;
  isExpandible?: boolean;
  isDisabled?: boolean;
  optionHeader?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: () => void;
}

const OptionPanel: React.FC<OptionPanelProps> = ({
  className,
  isExpandible,
  isDisabled,
  title,
  icon = 'info',
  optionHeader,
  children,
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    if (isExpandible) {
      setIsExpanded(isExpanded => !isExpanded);
    }

    onClick?.();
  };

  return (
    <Container className={className}>
      <OptionHeader
        isDisabled={isDisabled}
        tabIndex="0"
        onClick={handleClick}
        onKeyDown={handleEnterKeyDown(handleClick)}
      >
        <ChevronIcon icon="chevronRight" iconSize="24" isRotated={isExpanded} />
        {optionHeader || (
          <>
            <OptionIconContainer>
              {icon && <Icon icon={icon} iconSize="20" />}
            </OptionIconContainer>
            <Text>{title}</Text>
          </>
        )}
      </OptionHeader>
      {isExpanded && <OptionContent>{children}</OptionContent>}
    </Container>
  );
};

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`;

const Container = styled(Panel)`
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
  overflow: hidden;
`;

const OptionHeader = styled.div<{
  isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 48px 16px 16px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ isDisabled }) =>
    isDisabled ? Color.grey100 : Color.transparent};

  &:hover {
    background-color: ${Color.grey100};
  }
`;

const OptionContent = styled.div<{
  isShown?: boolean;
}>`
  margin-top: 16px;
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  animation: ${slideIn} 0.7s ease-in-out;
  transition: height, margin 0.7s linear;
`;

const ChevronIcon = styled(Icon)<{
  isRotated?: boolean;
}>`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  color: ${Color.grey400};
  ${({ isRotated }) => (isRotated ? 'transform: rotate(90deg)' : '')};
  transition: all 0.3s ease-in-out;
`;

const OptionIconContainer = styled.div`
  display: inline-flex;
  align-self: flex-start;
  padding: 8px;
  border-radius: 8px;
  background-color: ${Color.blue100};
  color: ${Color.blue400};
`;

export default OptionPanel;
