import styled from 'styled-components';
import { Text } from '@packages/ui';

export const MessageBody = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  white-space: pre-wrap;
`;
