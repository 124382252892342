import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Color } from '@packages/ui';

import { OAuthButton, AuthCallbackProps } from '../OAuthButton';

import {
  OAuthButtonType,
  OAuthRole,
  OAuthClientType,
} from 'main/data/types/OAuth';

export interface OAuthProps {
  role: OAuthRole;
  clientType?: OAuthClientType;
  signupType?: string;
  buttons: OAuthButtonType | Array<OAuthButtonType>;
  showDivider?: boolean;
  onSelect: ({ authType, accountType }: AuthCallbackProps) => void;
}

const OAuth: React.FC<OAuthProps> = ({
  role,
  clientType,
  buttons,
  signupType,
  showDivider = true,
  onSelect,
}) => {
  const { t } = useTranslation('portal');
  const authOptions = Array.isArray(buttons) ? buttons : [buttons];

  return (
    <>
      <ButtonBlock>
        {authOptions.map(button => (
          <OAuthButton
            key={button.name}
            type={role}
            signupType={signupType}
            clientType={clientType}
            site={button.name}
            onSelect={onSelect}
          />
        ))}
      </ButtonBlock>
      {showDivider && (
        <TextLine>
          <span>{t('or')}</span>
        </TextLine>
      )}
    </>
  );
};

const ButtonBlock = styled.div`
  width: 100%;
`;

const TextLine = styled.div`
  width: calc(100% - 60px);
  text-align: center;
  border-bottom: 1px solid ${Color.grey300};
  line-height: 0.1em;
  margin: 20px 30px;

  > span {
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    background: ${Color.white};
    color: ${Color.grey600};
    padding: 0 20px;
  }
`;

export default OAuth;
