import { KeyCode } from 'shared/constants';

export const scrollIntoView = node => {
  if (!node) {
    return;
  }

  node.scrollIntoView?.({
    behavior: 'smooth',
    block: 'end',
    inline: 'nearest',
  });
};

export const handleEnterKeyDown = (callback: Function) => (
  event: React.KeyboardEvent<HTMLElement>,
) => {
  const { key } = event;

  if (key === KeyCode.ENTER) {
    callback?.();
  }
};

export const handleEvent = (callback: Function) => (
  event: React.MouseEvent | React.KeyboardEvent,
) => {
  if (
    event.type === 'click' ||
    (event as React.KeyboardEvent).key === KeyCode.ENTER
  ) {
    callback();
  }
};

const getElementPagePosition = element => {
  if (!element) {
    return {};
  }

  const rect = element.getBoundingClientRect();
  const win = element.ownerDocument.defaultView;

  return {
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset,
  };
};

export const isElementOutOfScreen = element => {
  if (!element) {
    return false;
  }

  const position = getElementPagePosition(element);
  const topOffset = position.top;
  const elementHeight = element.clientHeight;
  const windowHeight = window.innerHeight;
  return topOffset + elementHeight > windowHeight;
};
