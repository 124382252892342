export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts
      ?.pop()
      ?.split(';')
      .shift();
  }

  return undefined;
}
export function deleteCookie(name: string, domain: string = '') {
  document.cookie = `${name}=;domain=${domain};path=/;max-age=0`;
}
export function setCookie(name: string, value: string) {
  document.cookie = `${name}=${value}`;
}
const CookieUtil = {
  getCookie,
  deleteCookie,
  setCookie,
};
export default CookieUtil;
