// React
import React from 'react';
import styled from 'styled-components';
// Config
import { Color } from '@packages/ui';
// HTML Elements
const InputElement = styled.input`
  outline: none;
  margin: 0;
  border: 0;

  vertical-align: middle;
  display: inline-block;

  user-select: ${props => (props.readOnly ? 'none' : 'auto')};

  padding: 8px 16px;

  width: ${props => (props.width ? `${props.width}px` : '100%')};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'undefined')};
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : 'undefined')};
  height: ${props => props.height}px;

  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};

  box-sizing: border-box;

  color: ${Color.grey600};
  font-weight: 400;
  font-size: 14px;

  background-color: white;

  ::placeholder {
    color: ${Color.grey600};
    font-weight: 200;
  }

  ::selection {
    background: ${props => (props.readOnly ? Color.white : 'auto')};
  }
`;
// Type Definition
type Props = {
  ariaRequired?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  width?: number;
  label?: string;
  maxWidth?: number;
  minWidth?: number;
  height?: number;
  disabled?: boolean;
  readOnly?: boolean;
  value: string;
  placeholder?: string;
  maxLength?: number | null;
  tabIndex?: string;
  pattern?: string;
  className?: string;
  textAlign?: string;
  type?: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  onKeyDown?: (evt: KeyboardEvent) => void;
};
type State = {
  value: string;
};

class Input extends React.PureComponent<Props, State> {
  // eslint-disable-next-line react/sort-comp
  ref = React.createRef<HTMLInputElement>();

  static defaultProps = {
    height: 40,
    disabled: false,
    readOnly: false,
    placeholder: '',
    type: 'text',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.setValue(value);
    }
  }

  setValue = (value: string) => {
    this.setState({
      value,
    });
  };

  // eslint-disable-next-line react/sort-comp
  handleChange = (evt: any) => {
    this.update(evt.target.value);
  };

  handleAutoFill = e => {
    const { value } = this.state;
    const newValue = (this.ref.current && this.ref.current.value) || '';

    if (newValue === '' || (newValue !== '' && value === newValue)) {
      // AutoFill handler can be called on:
      // AutoComplete or on User Interaction
      return;
    }

    this.update(value);
  };

  update = input => {
    const { pattern, onChange } = this.props;
    const { value } = this.state;
    // Convert input value to match pattern, i.e: 12/09/2020
    const newValue = pattern // eslint-disable-next-line no-use-before-define
      ? convertValue(input, value, pattern)
      : input;
    this.setValue(newValue);
    if (onChange) onChange(newValue);
  };

  focus() {
    if (this.ref.current) this.ref.current.focus();
  }

  blur() {
    if (this.ref.current) this.ref.current.blur();
  }

  render() {
    const {
      ariaRequired,
      autoComplete,
      autoFocus,
      width,
      label,
      maxWidth,
      minWidth,
      height,
      disabled,
      placeholder,
      readOnly,
      maxLength,
      tabIndex = '0',
      pattern,
      className,
      textAlign,
      type,
      onFocus = () => {},
      onBlur = () => {},
      onClick = () => {},
      onKeyDown = () => {},
    } = this.props;
    const { value } = this.state;
    let max = maxLength || 256;

    if (pattern) {
      max = pattern.length;
    }

    return (
      <InputElement
        id={label}
        name={label}
        label={label}
        aria-required={ariaRequired}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        type={type}
        width={width}
        maxWidth={maxWidth}
        minWidth={minWidth}
        height={height}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={max}
        tabIndex={tabIndex}
        readOnly={readOnly}
        className={className}
        textAlign={textAlign}
        onAnimationStart={this.handleAutoFill}
        onChange={this.handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        onKeyDown={onKeyDown}
        ref={this.ref}
      />
    );
  }
}

export default Input;

function convertValue(newValue: string, currentValue: string, pattern: string) {
  const removed = newValue.length < currentValue.length;
  const stripped = newValue.replace(/[^a-zA-Z0-9 ]/g, '');
  let offset = 0;
  return Array.prototype.map
    .call(stripped, (letter, index) => {
      const symbol = pattern.charAt(index + offset);
      const char = stripped.charAt(index);

      if (symbol && symbol.toLowerCase() !== 'x') {
        if (!removed || (removed && index !== stripped.length)) {
          offset += 1;
          return `${symbol}${char}`;
        }
      }

      return char;
    })
    .join('');
}
