/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */

export const roundToNearest10 = (value: number, roundMethod?: string) => {
  const roundFunction =
    roundMethod === 'ceil'
      ? Math.ceil
      : roundMethod === 'floor'
      ? Math.floor
      : Math.round;

  const absoluteValue = Math.abs(value);

  const roundedValue =
    absoluteValue < 10
      ? absoluteValue
      : absoluteValue < 100
      ? roundFunction(absoluteValue / 10) * 10
      : absoluteValue < 1000
      ? roundFunction(absoluteValue / 100) * 100
      : absoluteValue < 10_000
      ? roundFunction(absoluteValue / 1000) * 1000
      : absoluteValue < 100_000
      ? roundFunction(absoluteValue / 10_000) * 10_000
      : absoluteValue < 1_000_000
      ? roundFunction(absoluteValue / 100_000) * 100_000
      : absoluteValue < 10_000_000
      ? roundFunction(absoluteValue / 1_000_000) * 1_000_000
      : absoluteValue < 100_000_000
      ? roundFunction(absoluteValue / 10_000_000) * 10_000_000
      : absoluteValue < 1_000_000_000
      ? roundFunction(absoluteValue / 100_000_000) * 100_000_000
      : roundFunction(absoluteValue / 1_000_000_000) * 1_000_000_000;

  return Math.sign(value) < 0 ? -roundedValue : roundedValue;
};

export const roundToNext10 = (value: number) => Math.ceil(value / 10) * 10;

export const linearToLogarithmic = (linearValue: number, min: number, max: number) => {
  const logMin = Math.max(Math.log10(min), 0);

  const logMax = Math.log10(max);

  const logValue = linearValue * (logMax - logMin) + logMin;

  let value = Math.pow(10, logValue);

  if (value < min) {
    value = min;
  } else if (value > max) {
    value = max;
  }

  return Math.round(value);
};

export const logarithmicToLinear = (
  originalValue: number,
  min: number,
  max: number,
) => {
  const normalizedValue = originalValue - min + 1;

  if (normalizedValue <= 0) {
    return 0;
  }

  if (originalValue >= max) {
    return 1;
  }

  const logMin = Math.max(Math.log10(min), 0);
  const logMax = Math.log10(max);
  const logValue = Math.max(Math.log10(originalValue), 0);

  return (logValue - logMin) / (logMax - logMin);
};

export const getZeroPaddedNumber = (value: number, length: number) => {
  let valueAsString = String(value);

  while (valueAsString.length < length) {
    valueAsString = `0${valueAsString}`;
  }

  return valueAsString;
};

export const getMaxPropertyCount = (data: object[]) => {
  const counts = data
    .map(item => Object.values(item).filter(value => typeof value === 'number'))
    .flat();

  return Math.max(...counts);
};

export const getPercentage = (value: number, total: number) =>
  (value / total) * 100;

/**
 * @desc
 * Clamp an input value to a range of numbers
 * @parma {number} input - Value to restrict
 * @parma {number} min - Minimum number value can be
 * @parma {number} max - Maximum number value can be
 */
export function clamp(input: number, min: number, max: number) {
  return Math.min(Math.max(input, min), max);
}

/**
 * @desc
 * Clamp an input value to a range of numbers
 * @parma {number} input - Value to alter
 * @parma {number} oldMin - Orignal minimum value could be
 * @parma {number} oldMax - Orignal maximum value could be
 * @parma {number} newMin - New minimum value can be
 * @parma {number} newMax - New maximum value can be
 * @parma {boolean} [clampOutput=true] - Clamp value to new range
 */
export function range(
  input: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number,
  clampOutput: boolean = true,
) {
  const oldRange = oldMax - oldMin;
  const newRange = newMax - newMin;
  const newValue = ((input - oldMin) * newRange) / oldRange + newMin;
  return clampOutput === false
    ? newValue
    : clamp(newValue, Math.min(newMin, newMax), Math.max(newMin, newMax));
}
export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

  