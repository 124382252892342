import React, { useRef, useEffect, useState } from 'react';
import { TweenMax, Power2 } from 'gsap/TweenMax';
import styled from 'styled-components';
import { Color } from '@packages/ui';

import { SectionHeader } from '../SectionHeader';

const ResponsColor = {
  error: Color.red600,
  success: '#6549c7',
};

export interface FormHeaderProps {
  feedback: {
    text: string;
    type: string;
  };
  className?: string;
  children: string;
  role?: string;
  withUnderline?: boolean;
}

const FormHeader: React.FC<FormHeaderProps> = ({
  feedback = { type: '', text: '' },
  className,
  children,
  role,
  withUnderline = true,
}) => {
  // Component Variable
  const $feedback = useRef<Text>();

  // State Variable
  const [visible, setVisibility] = useState<boolean>(false);

  // Previous Reference Variable
  const prevFeedbackRef = useRef(feedback);
  // Store previous feedback, in order to prevent animating the same text
  useEffect(() => {
    prevFeedbackRef.current = feedback;
  });
  const prevText = prevFeedbackRef.current ? prevFeedbackRef.current.text : '';

  useEffect(() => {
    const color =
      feedback && feedback.type === 'error'
        ? ResponsColor.error
        : ResponsColor.success;

    const animateTextIn = () => {
      TweenMax.set($feedback.current, { color });
      TweenMax.fromTo(
        $feedback.current,
        0.35,
        { y: 5 },
        { y: 0, opacity: 1, ease: Power2.easeOut },
      );
    };

    const animateTextOut = () => {
      TweenMax.fromTo(
        $feedback.current,
        0.35,
        { y: 0 },
        { y: 5, opacity: 0, ease: Power2.easeOut },
      );
    };

    const aniamteNextText = () => {
      TweenMax.to($feedback.current, 0.3, {
        y: -5,
        opacity: 0,
        ease: Power2.easeOut,
        onComplete: () => {
          TweenMax.set($feedback.current, { color });
          TweenMax.fromTo(
            $feedback.current,
            0.3,
            { y: 5 },
            { y: 0, opacity: 1, ease: Power2.easeOut },
          );
        },
      });
    };

    if (feedback && feedback.text !== '') {
      if (feedback.text === prevText) return;

      if (visible) aniamteNextText();
      else animateTextIn();
    } else {
      animateTextOut();
    }

    setVisibility(!!feedback);
  }, [feedback, visible, prevText]);

  return (
    <Wrapper>
      {withUnderline ? (
        <SectionHeader data-cy="form-header" className={className}>
          {children}
        </SectionHeader>
      ) : (
        <Header data-cy="form-header" className={className}>
          {children}
        </Header>
      )}
      <Text
        data-cy="form-feedback"
        ref={$feedback}
        isVisible={feedback && feedback.text}
        role={role}
      >
        {feedback ? feedback.text : null}
      </Text>
    </Wrapper>
  );
};

FormHeader.defaultProps = {
  className: '',
};

const Wrapper = styled.div`
  width: 100%;
  height: auto;
`;

const Text = styled.div`
  width: 100%;
  height: auto;

  line-height: 15px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;

  opacity: 0;

  display: ${props => (props.isVisible ? 'block' : 'none')};
`;

const Header = styled.h1`
  height: auto;
  line-height: 20px;

  text-align: left;
  font-size: 24px;
  font-weight: 600;
  color: ${Color.grey600};

  margin-bottom: 5px;
  margin-top: 0;
`;

export default FormHeader;
