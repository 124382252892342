// NPM
import * as Sentry from '@sentry/browser';

const User = {
  data: {},
  CSRFToken: null,

  setCSRFToken(token: string) {
    this.CSRFToken = token;
  },

  getCSRFToken() {
    return this.CSRFToken;
  },

  getUserId() {
    return this.data?.id;
  },

  getRoleId() {
    return this.data?.role_id;
  },

  setData(data: any) {
    this.data = data;
    Sentry.configureScope(scope => {
      scope.setExtra('User data', {
        Email: data.email,
        'Is Requester': data.is_requester,
        'Is Webmaster': data.is_webmaster,
        'Is Pro': data.is_pro_publisher,
        'Is Enterprise Publisher': data.is_enterprise_publisher,
        'Is Accessibility': data.is_accessibility,
      });
    });
  },

  getData() {
    return this.data;
  },

  isRequester() {
    const { is_requester: isRequester = false } = this.data;
    return isRequester;
  },

  isPublisher() {
    const { is_webmaster: isWebmaster = false } = this.data;
    return isWebmaster;
  },

  isProPublisher() {
    const { is_pro_publisher: isProPublisher = false } = this.data;
    return isProPublisher;
  },

  isPartner() {
    const { is_partner: isPartner = false } = this.data;
    return isPartner;
  },

  isOrgOwner() {
    const { organizations } = this.data;
    const hasOrganizations = !!organizations?.length;
    return hasOrganizations;
  },

  isEnterprisePublisher() {
    const {
      is_enterprise_publisher: isEnterprisePublisher = false,
    } = this.data;
    return isEnterprisePublisher;
  },

  isAnalyst() {
    const { is_analyst: isAnalyst = false } = this.data;
    return isAnalyst;
  },

  isAccessibility() {
    const { is_accessibility: isAccessibility = false } = this.data;
    return isAccessibility;
  },

  isStaff() {
    const { is_staff: isStaff = false } = this.data;
    return isStaff;
  },

  isBetaSVRules() {
    const { is_staff: isStaff = false } = this.data;
    return isStaff;
  },

  isSupport() {
    const { is_support: isSupport = false } = this.data;
    return isSupport;
  },

  isRuleReviewEnabled() {
    const { rule_review_enabled: isReviewEnabled = false } = this.data;
    return isReviewEnabled;
  },

  getRuleReviewWebhooks() {
    const {
      rule_review_webhooks: ruleReviewWebhooks = {
        slack: [],
        email: [],
      },
    } = this.data;
    return ruleReviewWebhooks;
  },

  getRetroTaggingWebhooks() {
    const {
      retro_tagging_webhooks: retroTaggingWebhooks = {
        slack: [],
        email: [],
      },
    } = this.data;
    return retroTaggingWebhooks;
  },

  getPlan() {
    if (this.isEnterprisePublisher()) {
      return 'enterprise';
    }

    if (this.isProPublisher()) {
      return 'pro';
    }

    return 'free';
  },

  hasAllowedAccounts() {
    const { available_accounts: allowedAccounts = [] } = this.data;
    return allowedAccounts.length > 1;
  },

  hasVerifiedEmail() {
    const { email_verified: hasVerifiedEmail = false } = this.data;
    return hasVerifiedEmail;
  },

  hasSelectedUser() {
    const { logged_in_from_another_account } = this.data;
    return logged_in_from_another_account !== null;
  },

  hasMultiUser() {
    const { features } = this.data;
    return features?.multiuser;
  },

  isPl() {
    const { features } = this.data;
    return features?.pl;
  },

  isSsoUser() {
    return !!this.data?.is_sso_from;
  },

  isZoneRestrictedAccount() {
    return !!this.data?.is_zone_restricted_account;
  },

  isSharedAccount() {
    return !!this.data?.is_shared;
  },

  hasPasswordSet() {
    return !!this.data?.has_password;
  },

  is2FaEnabled() {
    return !!this.data?.is_2fa_enabled;
  },
};
export default User;
