import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TEXT_BRANDING } from 'shared/config/Config';

const Divider = <> - </>;

const Legal = () => {
  const { t } = useTranslation('portal');

  return (
    <Wrapper>
      <Link
        href="https://www.hcaptcha.com/terms"
        rel="noopener"
        data-cy="link-terms"
        aria-label={t('Read more about {{brand}} Terms', {
          brand: TEXT_BRANDING,
        })}
        tabIndex="0"
        target="_blank"
      >
        {t('Terms')}
      </Link>
      {Divider}
      <Link
        href="https://www.hcaptcha.com/privacy"
        rel="noopener"
        data-cy="link-terms"
        aria-label={t('Read more about {{brand}} Privacy', {
          brand: TEXT_BRANDING,
        })}
        tabIndex="0"
        target="_blank"
      >
        {t('Privacy')}
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  margin: 0 5px;
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;

  color: #007cbf;
  font-size: 10px;
  font-weight: 300;

  :hover {
    color: #006196;
  }
`;

export default Legal;
