export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const DAY_X3 = 3 * DAY;
export const WEEK = 7 * DAY;

export enum DateFormat {
  FORMAT_12 = '12',
  FORMAT_24 = '24',
}

export const WEEKDAY_2_WORDS = 'eeeeee';
