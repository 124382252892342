import { useToast } from '@packages/ui';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { copyToClipboard } from 'shared/utils/copyToClipboard';

export const useCopyToClipboard = () => {
  const { t } = useTranslation('generic');
  const toast = useToast();

  return useCallback(
    (value = '') => {
      const copySuccess = copyToClipboard(value);

      if (!copySuccess) {
        toast.error(t('Error occurred while copying to the clipboard'));

        return;
      }

      toast.info(t('Value copied to the clipboard'));
    },
    [t, toast],
  );
};
