export enum PlanType {
  FREE = 'free',
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
}

export enum PlanStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  INACTIVE = 'inactive',
}

export enum ProPlanType {
  YEARLY = 'pro_publisher_yearly_plan',
  MONTHLY = 'pro_publisher_monthly_plan',
}
