import styled from 'styled-components';
import { Color, Text } from '@packages/ui';

export const MessageTitle = styled(Text)`
  width: 100%;

  color: ${Color.grey500};
  text-align: center;
  font-weight: 600;
  font-size: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 16px;
`;
