// NPM
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Routes from './Routes';

import Account, { User } from 'shared/models/Account';

const AppRouter = () => {
  const getDefaultUrl = () => {
    let defaultUrl = '';

    if (!Account.loggedIn) {
      defaultUrl = '/login';
    } else if (
      User.isPublisher() ||
      User.isProPublisher() ||
      User.isEnterprisePublisher() ||
      User.isStaff() ||
      User.isPartner()
    ) {
      if (
        !User.isStaff() &&
        User.hasAllowedAccounts() &&
        !User.hasSelectedUser()
      ) {
        defaultUrl = '/choose_account';
      } else if (User.isAnalyst()) {
        defaultUrl = '/analyst_home';
      } else {
        defaultUrl = '/overview';
      }
    } else if (User.isRequester()) {
      defaultUrl = '/jobs';
    } else if (User.isSupport()) {
      defaultUrl = '/support';
    } else {
      defaultUrl = '/welcome_accessibility';
    }

    return defaultUrl;
  };

  // Create React page routes / redirects
  const PageRoutes = Routes.map(
    ({ path, component: Component, auth = false, routes = [] }, key) => {
      if (auth) {
        // get features which are turned ON for user
        const { features } = User.getData();
        let availableFeatures: string[] = [];

        if (features) {
          availableFeatures = Object.keys(User.getData().features).filter(
            val => features[val],
          );
        }

        const roleRoutes = routes.filter(({ config = {} as any }) =>
          config.featureName && availableFeatures.includes(config.featureName)
            ? true
            : User.isPublisher() || User.isPartner()
            ? config.publisher
            : User.isStaff()
            ? config.staff
            : User.isRequester()
            ? config.requester
            : User.isProPublisher()
            ? config.proPublisher
            : User.isEnterprisePublisher()
            ? config.enterprisePublisher
            : User.isSupport()
            ? config.support
            : config.accessibility,
        );
        return (
          <PrivateRoute
            exact
            path={path}
            key={key}
            isAuthenticated={Account.loggedIn}
            routes={roleRoutes}
            component={Component}
          />
        );
      }

      return (
        <Route
          exact
          path={path}
          key={key} // eslint-disable-next-line react/jsx-props-no-spreading
          render={props => <Component {...props} routes={routes} />}
        />
      );
    },
  );
  return (
    <Switch>
      <Redirect exact from="/" to={getDefaultUrl()} />
      {PageRoutes}
    </Switch>
  );
};

export default AppRouter;
