import React from 'react';
import styled from 'styled-components';
import { Color, Text } from '@packages/ui';
import { useTranslation } from 'react-i18next';

import { formatPrice } from 'shared/utils/FormatUtil';
import { ProPlanType } from 'shared/constants';

export interface DetailsBarProps {
  className?: string;
  data?: {
    id: string;
    total: number;
    sub_amount?: number;
    discountPercentage?: number;
  };
}

const DetailsBar: React.FC<DetailsBarProps> = ({ className = '', data }) => {
  const { t } = useTranslation('portal');
  const isYearlyPlanSelected = data?.id === ProPlanType.YEARLY;
  const selectedPlanPrice = formatPrice(Number(data?.total), 0);
  const isDiscountApplied = !!data?.discountPercentage;

  if (!data) {
    return null;
  }

  const priceMultiplier = data.discountPercentage
    ? (100 - data.discountPercentage) / 100
    : 1;
  return (
    <Container>
      <PriceContainer className={className}>
        <PriceInfo>
          <PlanTitle variant="default">{t('Pro Plan')}</PlanTitle>
          {isDiscountApplied && (
            <>
              <PlanPrice variant="default" lineThrough>
                -{' '}
                <LineThroughText>
                  {formatPrice(Number(data?.sub_amount), 0)}
                </LineThroughText>
              </PlanPrice>
              <NewPlanPrice variant="default">
                {formatPrice(
                  Number((data?.sub_amount || 0) * priceMultiplier),
                  0,
                )}
              </NewPlanPrice>
              <PlanPrice variant="default">/ {t('Month')}</PlanPrice>
            </>
          )}
          {!isDiscountApplied && (
            <PlanPrice variant="default">
              - {formatPrice(Number(data?.sub_amount), 0)} / {t('Month')}
            </PlanPrice>
          )}
        </PriceInfo>
        {!isYearlyPlanSelected && (
          <BillingInfo variant="default">{t('Billed monthly')}</BillingInfo>
        )}
        {isYearlyPlanSelected && !isDiscountApplied && (
          <BillingInfo variant="default">
            {t('Billed yearly')} ({selectedPlanPrice})
          </BillingInfo>
        )}
        {isYearlyPlanSelected && isDiscountApplied && (
          <PriceInfo>
            <BillingInfo variant="default">
              {t('Billed yearly')}{' '}
              <LineThroughText>({selectedPlanPrice})</LineThroughText>
            </BillingInfo>
            <NewYearlyPriceInfo variant="default">
              {`($${data?.total * priceMultiplier})`}
            </NewYearlyPriceInfo>
          </PriceInfo>
        )}
      </PriceContainer>
      {isDiscountApplied && (
        <CouponCodeDisclaimer>
          <CouponCodeDisclaimerText>
            {t('The discount will be applied to your first billing cycle.')}
          </CouponCodeDisclaimerText>
        </CouponCodeDisclaimer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;

  @media (min-width: 500px) {
    margin: 40px 0;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ isDiscountApplied }) =>
    isDiscountApplied ? '4px 4px 0 0' : '4px'};
  background-color: ${Color.grey100};
  padding: 12px 16px;
`;

const CouponCodeDisclaimer = styled.div`
  border-radius: 0 0 4px 4px;
  background-color: ${Color.grey600};
  padding: 4px 16px;
`;

const CouponCodeDisclaimerText = styled.div`
  color: ${Color.white};
  font-size: 12px;
`;

const NewYearlyPriceInfo = styled(Text)`
  display: flex;
  color: ${Color.teal600};
  font-size: 12px;
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

const PlanTitle = styled(Text)`
  font-size: 14px;
  font-weight: 600;
`;

const LineThroughText = styled.span`
  text-decoration: line-through;
`;

const PlanPrice = styled(Text)`
  font-size: 14px;
  line-height: 1.4;
  padding: 0 5px;
`;

const NewPlanPrice = styled(Text)`
  font-size: 14px;
  line-height: 1.4;
  padding: 0 5px;
  color: ${Color.teal600};
`;
const BillingInfo = styled(Text)`
  display: flex;
  color: ${Color.grey500};
  font-size: 12px;
`;

export default DetailsBar;
