import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Size, Color } from '@packages/ui';

import Logo from 'shared/components/logo/Logo';
import { IS_FOUNDATION } from 'shared/config/Config';

const LOGO_HEIGHT = IS_FOUNDATION ? 190 : 32;
const LOGO_WIDTH = IS_FOUNDATION ? 190 : 125;

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Color.white};
  padding: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

export const StyledContinueLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
  color: ${Color.grey500};

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const StyledLogo = styled(Logo)`
  margin: 32px auto;

  @media (min-width: ${Size.tablet}) {
    margin: 32px;
  }
`;

export const PageLogo = ({ className = '' }) => (
  <StyledLogoWrapper>
    <StyledLogo
      className={className}
      width={LOGO_WIDTH}
      height={LOGO_HEIGHT}
      stack={false}
      compact={false}
    />
  </StyledLogoWrapper>
);
