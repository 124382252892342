// React
import React from 'react';
import styled from 'styled-components';
import { Size } from '@packages/ui';

import { Input } from '../Input';

// Config Variables
// HTML Elements
const Container = styled.div.attrs(props => ({
  style: {
    width: props.width ? `${props.width}px` : '100%',
    height: `${props.height}px`,
  },
}))`
  height: ${props => props.height}px;
  z-index: 5;

  @media (max-width: ${Size.mobile}) {
    max-width: 100%;
  }
`;
const StyledInput = styled(Input)`
  @media (max-width: ${Size.mobile}) {
    max-width: calc(100% - 40px);
  }
`;
// Type Definitions
type Props = {
  ariaRequired?: boolean;
  autoComplete?: string;
  label?: string;
  height: number;
  width?: number;
  value?: string | null | undefined;
  placeholder: string;
  options: Array<any>;
  className?: string;
  onFilter?: (options: Array<any>) => void;
  filterMethod?: (contains_token: string) => Promise<Array<any>>;
  onFocus?: () => void;
  onClear?: () => void;
};
type State = {
  results: Array<any>;
};

class AutoComplete extends React.PureComponent<Props, State> {
  // eslint-disable-next-line react/sort-comp
  ref = React.createRef<Input>();

  handleChange = async (value: string) => {
    const { options, onFilter, filterMethod, onClear } = this.props;
    let results: any[] = [];

    if (filterMethod) {
      results = await filterMethod(value);
    } else {
      // eslint-disable-next-line no-use-before-define
      results = filter(value, options);
    }

    if (onFilter) onFilter(results);
    if (value === '' && onClear) onClear();
  };

  focus() {
    if (this.ref.current) this.ref.current.focus();
  }

  blur() {
    if (this.ref.current) this.ref.current.blur();
  }

  render() {
    const {
      width,
      height,
      value,
      placeholder,
      onFocus,
      className,
      ariaRequired,
      autoComplete,
      label,
    } = this.props;
    return (
      <Container width={width} height={height} className={className}>
        <StyledInput
          ariaRequired={ariaRequired}
          autoComplete={autoComplete}
          label={label}
          width={width}
          height={height}
          value={value}
          placeholder={placeholder}
          onChange={this.handleChange}
          onFocus={onFocus}
          ref={this.ref}
        />
      </Container>
    );
  }
}

export default AutoComplete;
export type AutoCompleteType = {
  setValue: (value: string) => void;
  handleChange: (value: string) => void;
  handleKeyPress: (evt: React.KeyboardEvent<any>) => void;
};

// Filter List Items
function filter(input, list) {
  if (input === '') return list;
  return list.filter(({ label }) => {
    const keyword = label.toLowerCase();
    return keyword.includes(input.toLowerCase());
  });
}
