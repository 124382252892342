/* eslint-disable no-underscore-dangle */
import React from 'react';

/**
 * Props supplied to automate children components
 * @param {any} children - component element that you want to assign
 * @param {Object[]} bindings - array of binding objects containing type and events
 * @param {string} bindings[].type - a special "defaultProps" defined in the children component level ("__TYPE")
 * @param {Object} bindings[].events - object containing events to bind to the children
 * @returns {React.ReactNode} - returns React nodes with additional props added
 */

interface Binding {
  type?: string; // React.defaultProps can possibly be undefined
  events: { [key: string]: any };
}

/**
 * Recursively adds props to children based on supplied bindings
 * @param {React.ReactNode | React.ReactNode[]} children - children elements to which props are to be assigned
 * @param {Object[]} bindings - array of binding objects containing type and events
 * @returns {React.ReactNode[]} - returns array of React nodes with additional props added
 */

export default function addPropsToChildren(children: any, bindings: Binding[]) {
  return recursiveChildren(children, bindings);
}

/**
 * Recursively processes children and applies props based on bindings
 * @param {React.ReactNode | React.ReactNode[]} children - The children elements
 * @param {Object[]} bindings - The bindings to apply to the children
 * @returns {React.ReactNode[]} - The processed children with props applied
 */

function recursiveChildren(children: any, bindings: Binding[]) {
  let items = children;
  if (!Array.isArray(children)) {
    items = [children];
  }

  return items.map((item: any, index: number) => {
    const matchingBindings = bindings.filter(binding =>
      Array.isArray(item.props?.__type)
        ? item.props.__type.includes(binding.type)
        : item.props?.__type === binding.type,
    );

    // If there are no matching bindings, return the original item
    if (!matchingBindings.length) {
      return item;
    }

    if (Array.isArray(item)) {
      // If children is an array, iterate over them
      return recursiveChildren(item, bindings);
    }

    const props = createProps(item.props, matchingBindings);

    // Clone element to attach props
    return React.cloneElement(item, {
      key: `item-${index}`,
      ...props,
    });
  });
}

/**
 * Creates props to be added to the child element
 * @param {Object} props - current props of the child element
 * @param {Object[]} bindings - array of binding objects containing type and events
 * @returns {Object} - returns object with additional props added
 */

function createProps(props: any, bindings: Binding[]) {
  const propsWithEvents = { ...props };

  bindings.forEach((binding: Binding) => {
    Object.keys(binding.events).forEach((key: string) => {
      const currentEvent = binding.events[key];

      if (propsWithEvents[key] && typeof propsWithEvents[key] === 'function') {
        const previousEvent = propsWithEvents[key];

        propsWithEvents[key] = (event: any) => {
          previousEvent(event);
          currentEvent(event);
        };
      } else {
        propsWithEvents[key] = currentEvent;
      }
    });
  });

  // Example of object returned
  /**
   * Example of object returned by createProps function
   * @typedef {Object} ExampleObject
   * @property {Function} onClick - Function to handle onClick event
   * @example
   * {
   *   onClick: (event) => {
   *     props.onClick(event);
   *     listeners.onClick(event);
   *   }
   * }
   */

  return propsWithEvents;
}
