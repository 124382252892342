import React from 'react';
import styled from 'styled-components';
import { Button, Color } from '@packages/ui';

import { KeyCode } from 'shared/constants';

interface MessageCtaButtonProps {
  onAction?: () => void;
  children: React.ReactNode;
}

export const MessageCtaButton: React.FC<MessageCtaButtonProps> = ({
  onAction,
  children,
}) => {
  const handleEvent = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (
      event.type === 'click' ||
      (event.type === 'keydown' &&
        (event as React.KeyboardEvent).key === KeyCode.ENTER)
    ) {
      onAction?.();
    }
  };

  return (
    <StyledButton
      tabIndex={0}
      onClick={handleEvent}
      onKeyDown={handleEvent}
      variant="outlined"
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  display: block;
  width: fit-content;

  border-radius: 8px;
  border: 1px solid ${Color.grey400};
  color: ${Color.grey500};

  margin-top: 32px;
`;
