export function download(
  data: string,
  fileName: string = '',
  contentType: string = 'text/plain',
): void {
  const anchor = document.createElement('a');
  const file = new Blob([data], {
    type: contentType,
  });
  anchor.href = URL.createObjectURL(file);
  anchor.download = fileName;
  anchor.click();
}
