import * as React from 'react';
import styled from 'styled-components';
import { Color } from '@packages/ui';

export interface RadioInputProps {
  children: React.ReactNode;
  checked: boolean;
  onChange: Function;
  value: string | boolean;
  isDisabled?: boolean;
  className?: string;
  radioClassName?: string;
  dataCy?: string;
}

const RadioInput = ({
  children,
  value,
  checked,
  onChange,
  isDisabled,
  className,
  radioClassName,
  dataCy,
}: RadioInputProps) => (
  <Container
    data-cy={dataCy}
    className={className}
    onClick={() => onChange(value)}
  >
    <Input
      type="radio"
      className={radioClassName}
      checked={checked}
      value={dataCy}
      readOnly
      disabled={isDisabled}
    />
    {children}
  </Container>
);

const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em currentColor;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:disabled {
    background-color: ${Color.grey200};
    cursor: not-allowed;
  }
`;

const Container = styled.div`
  cursor: pointer;
  margin-top: 5px;
`;

export default RadioInput;
