/* eslint-disable import/prefer-default-export */
export const getValueWithoutSpaces = (value: string) =>
  value.replace(/\s/g, '');

export const containsSpaces = (value: string) => /\s/g.test(value);

export const getValueCapitalized = (value: string) =>
  value
    .toLowerCase()
    .replace(/\w{1,}/g, match =>
      match.replace(/\w/, word => word.toUpperCase()),
    );

export const getSnakeCaseCapitalized = (value: string) =>
  value
    .match(/([^_]+)/g)
    ?.map(match => match.replace(/\w/, word => word.toUpperCase()))
    ?.join(' ') || value;

export const convertToTitleCase = (value: string) =>
  value
    .match(/[^_-]+/g)
    ?.map(match => match.replace(/^\w/, char => char.toUpperCase()))
    ?.join(' ') || value;

export const isStringBase64 = (value: string) => {
  const base64RegExp = /^[A-Za-z0-9+/]+={0,2}$/;
  return base64RegExp.test(value) && value.length % 4 === 0;
};

export const getInputValueTrimmed = (inputValue: string) =>
  inputValue.trim().replace(/\s+/g, ' ');
