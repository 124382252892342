/* eslint-disable no-param-reassign */

export const getGraphWidth = (width?: number) => {
  if (!width) {
    return 700;
  }

  const size = width > 1200 ? width * 0.65 : width * 0.55;

  if (size > 1000) {
    return 1000;
  }

  if (size < 550) {
    return 550;
  }

  return size;
};

export const getUniqueValues = (data: object[], key: string) => [
  ...new Set(data.map(item => item[key])),
];

/*
  Use getBarGraphDataFromKeys to process data based on a list of keys known to be present within items

  e.g.:

  data = [
    ...,
    {
      client_timestamp: '2023-10-19T12:00:00Z',
      key1: 1000,
      key2: 2000,
      key3: 3000,
    },
    ...
    {
      client_timestamp: '2023-10-19T12:00:00Z',
      key1: 1000,
      key2: 2000,
      key3: 3000,
    },
    ...
  ];

  getBarGraphDataFromKeys(data, [key1, key2, key3], ...);

  will yield:
  [
    ...,
    {
      client_timestamp: '2023-10-19T12:00:00Z',
      key1: 2000,
      key2: 4000,
      key3: 6000,
      total: 12000,
      ...
    },
    ...
  ]
*/
export const getBarGraphDataFromKeys = (
  data: object[],
  keys: string[],
  graphWidth: number,
  timestampKey = 'client_timestamp',
) => {
  const reducedCounts = data.reduce((accumulated, current) => {
    const currentDateValue = current?.[timestampKey];

    if (!accumulated[currentDateValue]) {
      accumulated[currentDateValue] = {};
    }

    for (const key of keys) {
      const accumulatedValue = accumulated[currentDateValue]?.[key] || 0;
      const currentValue = current[key] || 0;

      accumulated[currentDateValue][key] = accumulatedValue + currentValue;
    }

    return accumulated;
  }, {});

  const reducedWithTimestamps = Object.entries(reducedCounts)
    .map(([key, value]) => ({
      [timestampKey]: key,
      ...value,
    }))
    .sort(
      (a, b) =>
        new Date(a[timestampKey]).getTime() -
        new Date(b[timestampKey]).getTime(),
    );

  const processedData = reducedWithTimestamps.map((item, index) => {
    const mutatedItem = { ...item, total: 0 };

    for (const key of keys) {
      mutatedItem.total += item[key];
    }

    mutatedItem.xCoord = index * (graphWidth / reducedWithTimestamps.length);
    return mutatedItem;
  });

  return processedData;
};

/*
  Use getBarGraphDataFromValues to process data based on all found values of a target key across items

  e.g.:

  data = [
    ...,
    {
      client_timestamp: '2023-10-19T12:00:00Z',
      target: 'value_1',
      count: 2000,
    },
    ...
    {
      client_timestamp: '2023-10-19T12:00:00Z',
      target: 'value_2',
      count: 3000,
    },
    ...
  ];

  getBarGraphDataFromValues(
    data,
    'target',
    'count',
    ...,
  );

  will yield:
  [
    ...,
    {
      client_timestamp: '2023-10-19T12:00:00Z',
      value_1: 2000,
      value_2: 3000,
      total: 5000,
      ...
    },
    ...
  ]
*/

export const getBarGraphDataFromValues = (
  data: object[],
  targetKey: string,
  countKey: string,
  graphWidth: number,
  timestampKey = 'client_timestamp',
) => {
  const uniqueValues: string[] = [];

  const reducedCounts = data.reduce((accumulated, current) => {
    const currentTimestamp = current?.[timestampKey].split('T')[0];

    if (!accumulated[currentTimestamp]) {
      accumulated[currentTimestamp] = {};
    }

    const currentKey: string = current[targetKey];

    if (!uniqueValues.includes(currentKey)) {
      uniqueValues.push(currentKey);
    }

    const currentCount = current[countKey] || 0;

    if (accumulated[currentTimestamp][currentKey] === undefined) {
      accumulated[currentTimestamp][currentKey] = 0;
    }

    accumulated[currentTimestamp][currentKey] += currentCount;

    return accumulated;
  }, {});

  const reducedWithTimestampsAndMissingValues = Object.entries(reducedCounts)
    .map(([key, item]: [string, object]) => {
      const mutatedItem: object = {
        [timestampKey]: key,
        ...item,
      };

      for (const value of uniqueValues) {
        if (!(value in item)) {
          mutatedItem[value] = 0;
        }
      }

      return mutatedItem;
    })
    .sort(
      (a, b) =>
        new Date(a[timestampKey]).getTime() -
        new Date(b[timestampKey]).getTime(),
    );

  const processedData = reducedWithTimestampsAndMissingValues.map(
    (item: object, index) => {
      const mutatedItem: any = { ...item, total: 0 };

      for (const value of uniqueValues) {
        mutatedItem.total += mutatedItem[value];
      }

      mutatedItem.xCoord =
        index * (graphWidth / reducedWithTimestampsAndMissingValues.length);
      return mutatedItem;
    },
  );

  return processedData;
};

export const getFeatureTotal = (data: object[], featureName: string) =>
  data.reduce((accumulated: number, current) => {
    accumulated += current[featureName];
    return accumulated;
  }, 0);

export const getMaxY = (data: object[], totalReferenceKey: string) => {
  const totals = data.map(item => item[totalReferenceKey]);

  return Math.max(...totals);
};
