import React, { memo } from 'react';
import styled from 'styled-components';
import { Color } from '@packages/ui';

export interface BorderProps {
  width?: any;
  height?: number;
  children: React.ReactNode;
  className?: string;
  color?: string;
  dataCy?: string;
}

const Border: React.FC<BorderProps> = ({
  width,
  height,
  children,
  className = '',
  color,
  dataCy,
}) => {
  return (
    <BorderWrapper
      data-cy={dataCy}
      width={width}
      height={height}
      className={className}
      color={color}
    >
      {children}
    </BorderWrapper>
  );
};

const BorderWrapper = styled.div.attrs(props => ({
  style: {
    width: `${props.width}px`,
    height: `${props.height}px`,
  },
}))`
  border-color: ${({ color }) =>
    color ? Color[`${color}600`] : Color.grey400};
  border: 1px solid;
  border-radius: 4px;
`;

export default memo(Border);
