import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { useLocation, Switch, Redirect, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Tabs, Tab, PageContent, LoadingIndicator } from '@packages/ui';

import { RoutesArray } from 'shared/types/RouteType';
import History from 'shared/models/History';

export interface PageTabsProps {
  className?: string;
  routes: RoutesArray;
  indexRedirect: string;
  rootPath: string;
}

export const PageTabs: React.FC<PageTabsProps> = ({
  className = '',
  routes,
  indexRedirect,
  rootPath,
}) => {
  const location = useLocation();
  const getActiveTab = useCallback(() => location.pathname.split('/').pop(), [
    location,
  ]);

  const [activeTab, setActiveTab] = useState(getActiveTab());

  useEffect(() => {
    setActiveTab(getActiveTab);
  }, [getActiveTab, location.pathname]);

  const handleTabClick = (path: string = '') => {
    setActiveTab(path);
    History.push(path);
  };

  return (
    <Container className={className}>
      <Tabs>
        {routes.map(({ title = '', path }) => (
          <Tab
            key={title}
            value={path as string}
            isActive={activeTab === (path as string).split('/').pop()}
            onSelect={handleTabClick}
          >
            {title}
          </Tab>
        ))}
      </Tabs>
      <Content>
        <Suspense fallback={<LoadingIndicator />}>
          <Switch>
            <Redirect exact from={rootPath} to={indexRedirect} />
            {routes.map(({ path, component }) => (
              <Route key={path} exact path={path} component={component} />
            ))}
          </Switch>
        </Suspense>
      </Content>
    </Container>
  );
};

const Container = styled.div``;

const Content = styled(PageContent)`
  display: flex;
  position: relative;
  padding: 32px 0 0;
`;

export default PageTabs;
