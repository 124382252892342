import * as EmailValidator from 'email-validator';

const SLACK_URL_VALIDATION_REGEX = /^https:\/\/hooks\.slack\.com\/(services|triggers)\/[A-Z0-9]+\/[A-Z0-9]+\/[A-Za-z0-9]+$/;
const WEBHOOK_URL_VALIDATION_REGEX = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
const URL_VALIDATION_REGEX = /^((http|https):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
const IMAGE_URL_VALIDATION_REGEX = /^(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|svg))/;
const MIN_SLACK_URL_LENGTH = 78;

export const slackUrlValidator = (value: string) =>
  SLACK_URL_VALIDATION_REGEX.test(value) &&
  value.length >= MIN_SLACK_URL_LENGTH;

export const webhookValidator = (value: string) =>
  WEBHOOK_URL_VALIDATION_REGEX.test(value);

export const urlValidator = (value: string) => URL_VALIDATION_REGEX.test(value);

export const emailValidator = (value: string) => EmailValidator.validate(value);

export const imageUrlValidator = (value: string) =>
  IMAGE_URL_VALIDATION_REGEX.test(value);

export const isBase64Decoded256bits = (value: string) => {
  try {
    const decodedData = atob(value);
    return decodedData.length === 32;
  } catch (error) {
    return false;
  }
};
