import { Login } from '../views/Login';
import { Signup } from '../views/Signup';
import { PasswordReset } from '../views/PasswordReset';
import { SelectProPlan } from '../views/SelectProPlan';
import { SuccessPayment } from '../views/SuccessPayment';
import { PasswordChange } from '../views/PasswordChange';
import { EmailConfirmation } from '../views/EmailConfirmation';
import { SelectAccount } from '../views/SelectAccount';
import { VerifyLogin } from '../views/VerifyLogin';
import { AccountDeletionConfirmation } from '../views/AccountDeletionConfirmation';
import { Portal } from '../components/Portal';

import DashboardRouter from './DashboardRouter';

const Routes: any = [
  {
    path:
      '/(login|login/org|login/user|signup|password_reset|email_confirmation|verify-login)',
    auth: false,
    title: 'hCaptcha Login',
    component: Portal,
    config: {},
    routes: [
      {
        path: '/(login|login/org|login/user)',
        title: 'hCaptcha Login',
        component: Login,
        config: {},
      },
      {
        path: '/signup',
        title: 'Sign Up',
        component: Signup,
        config: {},
      },
      {
        path: '/password_reset',
        title: 'Password Reset',
        component: PasswordReset,
        config: {},
      },
      {
        path: '/email_confirmation',
        title: 'Email Confirmation',
        component: EmailConfirmation,
        config: {},
      },
      {
        path: '/verify-login',
        title: 'Verify Login',
        component: VerifyLogin,
        config: {},
      },
    ],
  },
  {
    path: '/org/:organization/login',
    auth: false,
    component: Portal,
    routes: [
      {
        path: '/org/:organization/login',
        title: 'hCaptcha Organization Login',
        component: Login,
      },
    ],
  },
  {
    path: '/(payment|payment/success|payment/select-plan)',
    auth: true,
    component: Portal,
    config: {
      publisher: true,
      proPublisher: false,
      requester: false,
      staff: true,
      enterprisePublisher: false,
      accessibility: false,
    },
    routes: [
      {
        path: '/payment/select-plan',
        title: 'Select subscription plan',
        component: SelectProPlan,
        config: {
          publisher: true,
          proPublisher: true,
          requester: false,
          staff: true,
          enterprisePublisher: false,
          accessibility: false,
        },
      },
      {
        path: '/payment/success',
        title: 'Payment succeed',
        component: SuccessPayment,
        config: {
          publisher: true,
          proPublisher: true,
          requester: false,
          staff: true,
          enterprisePublisher: false,
          accessibility: false,
        },
      },
    ],
  },
  {
    path: '/(password_change|choose_account)',
    auth: true,
    component: Portal,
    routes: [
      {
        path: '/password_change',
        title: 'Password Change',
        component: PasswordChange,
        config: {
          publisher: true,
          requester: true,
          staff: true,
          proPublisher: true,
          enterprisePublisher: true,
          accessibility: true,
        },
      },
      {
        path: '/choose_account',
        title: 'Choose account',
        component: SelectAccount,
        config: {
          proPublisher: true,
          enterprisePublisher: true,
          publisher: true,
          requester: false,
          staff: true,
        },
      },
    ],
  },
  {
    path: '/deletion-confirmation',
    auth: false,
    title: 'Account Deletion Confirmation',
    component: AccountDeletionConfirmation,
    config: {},
  },
  {
    path: '*',
    auth: true,
    title: 'Dashboard',
    component: DashboardRouter,
    config: {},
  },
];

export default Routes;
