import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@packages/ui';

const SubmitButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  // eslint-disable-next-line
  <StyledButton dataCy="button-submit" {...props}><span>{children}</span></StyledButton>
);

const StyledButton = styled(Button)`
  margin-bottom: 10px;
  padding: 0;
  border: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: auto;
  background-image: linear-gradient(180deg, #00c6bf, #008fbf, #4d9ed2);
  background-size: 100% 200%;
  color: #555;
  border-radius: 6px;
  height: auto;

  :hover,
  :focus {
    background-image: linear-gradient(180deg, #4d9ed2, #008fbf, #00c6bf);

    & span {
      background: #d0f4ff;
      color: #404040;
    }
  }

  & span {
    margin: 2px;
    background: white;
    padding: 10px 30px;
    border-radius: 4px;
    display: block;
  }
`;

export default SubmitButton;
