/* eslint-disable import/prefer-default-export */

// input expected to be in the format yyyy/mm
export const getDateFromMonthAndYear = (dateString: string) => {
  const splitDate = dateString.split('/');

  return new Date(`${splitDate[0]}-${splitDate[1]}-01T00:00:00Z`);
};

// input expected to be in the format yyyy/mm/dd
export const getDateFromFullDateString = (dateString: string) => {
  const splitDate = dateString.split('/');
  const month = +splitDate[1] < 10 ? `0${splitDate[1]}` : splitDate[1];
  const day = +splitDate[2] < 10 ? `0${splitDate[2]}` : splitDate[2];

  const date = new Date(`${splitDate[0]}-${month}-${day}T00:00:00Z`);

  return date;
};

export const getFollowingDayDate = (date: Date) => {
  return `${formatUTCMonth(date)}/${date.getUTCDate() + 1}`;
};

export const formatUTCMonth = (date: Date) => {
  const utcMonth = date.getUTCMonth() + 1;

  return utcMonth < 10 ? `0${utcMonth}` : `${utcMonth}`;
};

export const formatUTCDate = (date: Date) => {
  const utcDay = date.getUTCDate();

  return utcDay < 10 ? `0${utcDay}` : `${utcDay}`;
};

export const isValidDate = (date: string) =>
  !Number.isNaN(new Date(date).getTime());

export const getUTC = (value: string) => {
  const date = new Date(Number(value));

  return date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
};

export const getDaysFromMilliseconds = (milliseconds: number) => {
  const twentyFourHours = 24 * 60 * 60 * 1000;

  return Math.ceil(milliseconds / twentyFourHours);
};

// Add one minute to ensure Last 10 days don't match here
export const isOlderThan10Days = (timeStart: number) =>
  timeStart < Date.now() - 10 * 24 * 60 * 60 * 1000 - 60 * 1000;

export const getLocaleDateAndTime = (
  dateString: string,
  dateOptions?: Intl.DateTimeFormatOptions,
  hourOptions?: Intl.DateTimeFormatOptions,
  timeZone?: string,
) => {
  const date = new Date(dateString);
  const dateConfig = dateOptions || {
    timeZone,
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  };
  const timeConfig = hourOptions || {
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23', // ensures 00:00 is shown instead of 24:00 (to be used without "hour12: false")
  };

  return `${date.toLocaleString([], dateConfig)}${
    Object.keys(timeConfig).length
      ? ` – ${date.toLocaleString([], timeConfig)}`
      : ''
  }`;
};

export const getLocaleUTCDateAndTime = (
  dateString: string,
  dateOptions?: Intl.DateTimeFormatOptions,
  hourOptions?: Intl.DateTimeFormatOptions,
) => {
  return getLocaleDateAndTime(dateString, dateOptions, hourOptions, 'UTC');
};

export const timestampToGMTString = (dateString: string) => {
  const unixTimestamp = parseInt(dateString, 10) * 1000; // Convert to milliseconds
  const date = new Date(unixTimestamp);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  return `${dayOfWeek}, ${day} ${month} ${year} ${hours}:${minutes}:${seconds} GMT`;
};

export const generateUTCTimestamp = () => {
  const now = new Date();
  return `${now.toISOString().split('.')[0]}Z`;
};

export const timestampToUTCString = (dateString: string) => {
  let unixTimestamp;

  // Unix timestamp
  if (!isNaN(Number(dateString))) {
    unixTimestamp = parseInt(dateString, 10) * 1000; // Convert to milliseconds
  } else {
    // ISO 8601 date string
    unixTimestamp = Date.parse(dateString);
  }

  const date = new Date(unixTimestamp);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date
    .getUTCMinutes()
    .toString()
    .padStart(2, '0');
  const seconds = date
    .getUTCSeconds()
    .toString()
    .padStart(2, '0');

  return `${dayOfWeek}, ${day} ${month} ${year} ${hours}:${minutes}:${seconds} UTC`;
};

export const getDateByShortFormat = (date: string, separator: string = '-') => {
  const [year, month] = date.split(separator);
  const monthIndex = parseInt(month, 10) - 1;

  return new Date(parseInt(year, 10), monthIndex, 1);
};
