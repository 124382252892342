import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import * as EmailValidator from 'email-validator';
import { useTranslation } from 'react-i18next';
import { Color } from '@packages/ui';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { SubmitButton, Form, FormHeader, TextInput } from 'main/components';
import {
  hCaptcha,
  TEXT_BRANDING,
  IS_FOUNDATION,
  IS_LOCAL_ENV,
} from 'shared/config/Config';
import Account from 'shared/models/Account';
import Logger from 'shared/models/Logger';

const hCaptchaProps = {
  sitekey: hCaptcha.sitekey,
  endpoint: hCaptcha.endpoint,
  custom: IS_FOUNDATION,
  ...(IS_LOCAL_ENV
    ? {
        host: 'dashboard.local',
      }
    : {}),
};

const PasswordReset = () => {
  const { t } = useTranslation('portal');
  const [input, setInput] = useState<string>('');
  const [feedback, setFeedback] = useState({ text: '', type: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const captchaRef = useRef<HCaptcha>(null);
  const [token, setToken] = useState<string | null | undefined>(null);

  const onSubmit = async () => {
    if (!EmailValidator.validate(input)) {
      setFeedback({
        text: t('Please insert a valid email'),
        type: 'error',
      });
      return;
    }

    if (!token) {
      setFeedback({
        text: t('Please complete the form.'),
        type: 'error',
      });
      return;
    }

    try {
      setIsSubmitting(true);
      await Account.resetPassword(input, token);
      setFeedback({
        text: t('Password was reset, please check your email.'),
        type: 'success',
      });
    } catch (e) {
      Logger.error(`couldn't reset Password in PasswordReset: ${e}`);

      setFeedback({ text: t(e.message), type: 'error' });
    }
    setIsSubmitting(false);
  };

  const resetHeader = () => {
    setFeedback({ text: '', type: '' });
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { keyCode } = event;

    if (keyCode === 13) {
      // If enter press
      onSubmit();
    }
  };

  return (
    <Form
      ariaLabel={t('{{brand}} Password Reset', {
        brand: TEXT_BRANDING,
      })}
      width={300}
      minHeight={0}
      padding={0}
    >
      <FormHeader withUnderline={false} role="alert" feedback={feedback}>
        {t('Reset Password')}
      </FormHeader>

      <InputWrapper>
        <StyledLabel htmlFor="email">{t('Email')}</StyledLabel>
        <TextInput
          placeholder={t('Email')}
          label="email"
          onChange={setInput}
          onKeyDown={handleKeyDown}
          onFocus={resetHeader}
          ariaRequired
        />
      </InputWrapper>

      <HCaptchaWrapper data-cy="hCaptchaWrapper">
        <HCaptcha
          onVerify={setToken}
          onError={() =>
            setFeedback({
              text: t('Please complete the form.'),
              type: 'error',
            })
          }
          size="normal"
          ref={captchaRef}
          // eslint-disable-next-line
          {...hCaptchaProps}
        />
      </HCaptchaWrapper>

      <ButtonWrapper>
        <ActionButton
          onClick={onSubmit}
          aria-live="polite"
          aria-relevant="text"
          disabled={isSubmitting}
          aria-label={isSubmitting ? t('Submitting...') : t('Submit')}
        >
          {isSubmitting ? t('Submitting...') : t('Submit')}
        </ActionButton>
      </ButtonWrapper>
    </Form>
  );
};

const StyledLabel = styled.label`
  color: ${Color.grey600};
  font-weight: 500;
`;

const HCaptchaWrapper = styled.div``;

const InputWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ActionButton = styled(SubmitButton)`
  font-size: 14px;

  background-color: #0082bf;

  :hover {
    background-color: #0082bf;
  }

  :focus {
    background-color: #0082bf;
  }
`;

export default PasswordReset;
