import { loadStripe } from '@stripe/stripe-js/pure';

let stripePromise;
const getStripe = () => {
  if (!stripePromise)
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY || '');
  return stripePromise;
};

export default getStripe;
