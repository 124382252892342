import React from 'react';
import styled from 'styled-components';
import { Color, LoadingIndicator } from '@packages/ui';

const PageLoader = () => {
  return (
    <LoaderContainer data-testid="loader">
      <LoadingIndicator height="100vh" />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${Color.grey100};
  z-index: 99999999;
`;

export default PageLoader;
