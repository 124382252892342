import React, { useContext } from 'react';

import rootStoreInstance, { RootStore } from './RootStore';

const RootStoreContext = React.createContext<RootStore | null>(null);

export const RootStoreProvider: React.FC = ({ children }) => {
  return (
    <RootStoreContext.Provider value={rootStoreInstance}>
      {children}
    </RootStoreContext.Provider>
  );
};

export function useRootStore(): RootStore {
  const rootStore = useContext(RootStoreContext);

  if (rootStore === null) {
    throw new Error(
      'Root store is unavailable. Check if RootStoreProvider is in the current tree',
    );
  }

  return rootStore;
}

export const withStore = (Component: any) => (props: any) => {
  /* eslint-disable react/jsx-props-no-spreading */
  return <Component {...props} store={useRootStore()} />;
};
