import { useLayoutEffect } from 'react';

export default function useScrollPosition(
  scrollTargetRef: React.RefObject<HTMLElement>,
  callback: (elementPosition: DOMRect) => void,
  scrollableRef: React.RefObject<HTMLElement>,
) {
  useLayoutEffect(() => {
    // affords referring to the element on cleanup
    const scrollableElement = scrollableRef.current;

    const handleScroll = () => {
      if (scrollTargetRef.current) {
        callback(scrollTargetRef.current.getBoundingClientRect());
      }
    };

    if (scrollableElement) {
      scrollableElement.addEventListener('wheel', handleScroll);
    }

    return () => scrollableElement?.removeEventListener('wheel', handleScroll);
  }, [scrollableRef.current, scrollTargetRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
}
