import styled from 'styled-components';

export const MessageContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 32px;
`;
