import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { upperFirst } from 'lodash';
import { Color } from '@packages/ui';
import { Metric, useAnalytics } from '@packages/analytics';

import Google from 'shared/images/google.svg';
import Github from 'shared/images/github.svg';
import SAML from 'shared/images/saml.svg';
import { OAuthRole, OAuthSite } from 'main/data/types/OAuth';

export type AuthCallbackProps = {
  authType: string;
  accountType: string;
};

interface OAuthButtonProps {
  type: OAuthRole;
  site: OAuthSite;
  signupType?: string;
  clientType?: string;
  onSelect?: ({ authType, accountType }: AuthCallbackProps) => void;
}

const OAuthImages = {
  github: Github,
  google: Google,
  SAML,
};

export const OAuthButton: React.FC<OAuthButtonProps> = ({
  type = 'login',
  site = 'github',
  signupType = '',
  clientType = 'user',
  onSelect,
}) => {
  const { t } = useTranslation('portal');
  const { trackMetric } = useAnalytics();

  const text = t(
    `Sign ${type === 'login' ? 'in' : 'up'} with ${upperFirst(site)}`,
  );

  const image = OAuthImages[site] || SAML;

  const handleClick = () => {
    const isSignup = type === 'signup';

    if (isSignup) {
      trackMetric(
        Metric.SIGN_UP,
        {
          type: signupType,
          method: site.toLowerCase(),
        },
        () => {
          if (onSelect) {
            onSelect({ accountType: signupType, authType: site.toLowerCase() });
          }
        },
      );
    } else {
      trackMetric(
        Metric.SIGN_IN,
        {
          client: clientType,
          method: site.toLowerCase(),
        },
        () => {
          if (onSelect) {
            onSelect({ accountType: signupType, authType: site.toLowerCase() });
          }
        },
      );
    }
  };

  return (
    <Container
      onClick={handleClick}
      aria-label={text}
      tabIndex="0"
      role="button"
    >
      <AuthIcon src={image} />
      <AuthText data-cy={`oauth-text-${site.toLowerCase()}`}>{text}</AuthText>
    </Container>
  );
};

const Container = styled.div`
  outline: none !important;

  text-indent: 10px;

  line-height: 40px;
  color: ${Color.grey600};

  background-color: ${Color.white};
  user-select: none;

  cursor: pointer;
  margin: 10px 0px;
  border: 1px solid ${Color.grey300};
  border-radius: 4px;

  display: block;
  width: 100%;

  :hover,
  :focus {
    border-color: ${Color.grey600};
  }
`;

const AuthText = styled.span`
  vertical-align: middle;
  margin-left: 10px;
`;

const AuthIcon = styled.div`
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background: url(${props => props.src}) center / contain no-repeat;

  display: inline-block;
`;
