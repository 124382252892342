export enum AccountType {
  OWNER = 'owner',
  REGULAR = 'regular',
  ZONE = 'zone',
}

export enum AccountSection {
  FAVORITE = 'favorite',
  OWNER = 'owner',
  ZONE = 'zone',
  SHARED = 'shared',
}

export enum FavoriteAction {
  FAVORITE = 'favorite',
  REMOVE = 'remove',
}

export interface AllowedAccount {
  id: number;
  email: string;
  is_favorite: boolean;
  account_type: AccountType;
}
