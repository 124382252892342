import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { TEXT_BRANDING, COMPANY, LINK_COMPANY } from 'shared/config/Config';

export interface CopyrightProps {
  className?: string;
}

const Copyright: React.FC<CopyrightProps> = ({ className = '' }) => {
  const { t } = useTranslation('portal');

  const brand = TEXT_BRANDING || '';
  const company = COMPANY;
  const year = new Date().getFullYear();

  return (
    <Wrapper className={className}>
      <Trans t={t}>
        <a href={LINK_COMPANY}>{{ brand }}</a> is a registered trademark of{' '}
        {{ company }} © {{ year }}
      </Trans>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: 300;
  color: #555;

  position: relative;
  display: inline-block;

  margin: 0 5px;
`;

export default Copyright;
