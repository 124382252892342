export enum Color {
  white = '#FFFFFF',
  grey040 = '#F9FAFB',
  grey050 = '#F9FBFB',
  grey100 = '#F0F2F5',
  grey200 = '#D1D7E0',
  grey300 = '#B2BDCC',
  grey400 = '#94A3B8',
  grey500 = '#6E829E',
  grey600 = '#5C6F8A',
  grey700 = '#47566B',
  grey800 = '#3D4A5C',
  grey900 = '#262D38',
  grey1000 = '#12171B',
  red0 = '#FFF0F0',
  red050 = '#FFFAFA',
  red100 = '#FECFCF',
  red200 = '#FBAEAE',
  red300 = '#F98C8C',
  red350 = '#FF6464',
  red400 = '#EF3A3A',
  red500 = '#DB1212',
  red600 = '#C10A0A',
  red700 = '#A20D0D',
  red800 = '#610909',
  red900 = '#3B0404',
  orange0 = '#FFF7F0',
  orange100 = '#FFD9BE',
  orange200 = '#FEBB8C',
  orange300 = '#FD954B',
  orange400 = '#D65A00',
  orange500 = '#B84D00',
  orange600 = '#A84100',
  orange700 = '#903700',
  orange800 = '#742D00',
  orange900 = '#311501',
  yellow0 = '#FFFBF0',
  yellow100 = '#FCDB79',
  yellow150 = '#F2C94C',
  yellow200 = '#F9C31F',
  yellow300 = '#DFA907',
  yellow400 = '#A27A00',
  yellow500 = '#8B6901',
  yellow600 = '#7E5F00',
  yellow700 = '#694E00',
  yellow800 = '#564204',
  yellow900 = '#271D00',
  green0 = '#EAFBFA',
  green050 = '#F2FDFC',
  green100 = '#EAFBFA',
  green200 = '#8CECE2',
  green300 = '#4DE1D2',
  green400 = '#00D4BF',
  green500 = '#33C7CC',
  green600 = '#077C75',
  green700 = '#086C66',
  green800 = '#06615C',
  green900 = '#013733',
  blue0 = '#E7F7FF',
  blue100 = '#ABE1FB',
  blue200 = '#73CDF8',
  blue300 = '#3FA6F0',
  blue400 = '#0082BF',
  blue500 = '#0075AB',
  blue550 = '#0091E5',
  blue600 = '#026593',
  blue650 = '#0074BF',
  blue700 = '#03567C',
  blue800 = '#012A3E',
  blue900 = '#011210',
  purple0 = '#EAE7FF',
  purple100 = '#D1CBF9',
  purple200 = '#A398F0',
  purple300 = '#8E81E9',
  purple400 = '#7B6CE5',
  purple500 = '#6756E1',
  purple600 = '#5241CB',
  purple700 = '#4334B3',
  purple800 = '#362A8F',
  purple900 = '#0E0A29',
  turquoise0 = '#33D1CB',
}

export enum Size {
  space0 = '0px',
  space100 = '5px',
  space200 = '10px',
  space300 = '15px',
  space400 = '20px',
  space500 = '25px',
  space600 = '30px',
  space700 = '35px',
  space800 = '40px',

  radius100 = '2px',
  radius200 = '4px',

  font100 = '12px',
  font200 = '14px',
  font300 = '16px',
  font400 = '18px',
  font500 = '24px',
}
