const getUserPlanAndType = (role_id: string = '') => {
  let type = '-';
  let product = '-';
  let plan = '-';
  switch (role_id) {
    case 'webmaster':
      type = 'customer';
      product = 'publisher';
      plan = 'basic';
      break;
    case 'pro_publisher':
      type = 'customer';
      product = 'publisher';
      plan = 'professional';
      break;
    case 'enterprise_publisher':
      type = 'customer';
      product = 'publisher';
      plan = 'enterprise';
      break;
    case 'requester':
      type = 'customer';
      product = 'requester';
      break;
    case 'accessibility':
      type = 'accessibility';
      break;
    case 'staff':
      type = 'staff';
      break;
    case 'support':
      type = 'support';
      break;
    default:
      type = role_id;
      product = role_id;
      plan = role_id;
      break;
  }

  return { type, product, plan };
};

export default getUserPlanAndType;
