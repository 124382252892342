const REDIRECT_WHITE_LIST = ['docs.hcaptcha.com', 'dashboard.hcaptcha.com'];

export const isRedirectUrlAllowed = (url: string) => {
  if (process.env.NODE_ENV !== 'production') return true;

  try {
    const { hostname, protocol } = new URL(url);

    return REDIRECT_WHITE_LIST.includes(hostname) && protocol === 'https:';
  } catch (e) {
    return false;
  }
};

export const constructSafeUrl = (url: string) => {
  try {
    const { origin, pathname } = new URL(url);

    return `${origin}${pathname}`;
  } catch (e) {
    return '';
  }
};

export const getUrlWithBackurlRedirect = (url: string) => {
  const backurl = new URLSearchParams(window.location.search).get('backurl');

  if (backurl && isRedirectUrlAllowed(backurl)) {
    const urlInstance = new URL(url);
    const urlSearchParams = new URLSearchParams(urlInstance.search);

    urlSearchParams.append('next', constructSafeUrl(backurl));
    urlInstance.search = urlSearchParams.toString();

    return urlInstance.toString();
  }

  return url;
};
