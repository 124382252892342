const normalize = (min: number, max: number, value: number): number => {
  return (value - min) / (max - min);
};

const lerp = (min: number, max: number, t: number): number => {
  return (max - min) * t + min;
};

const clamp = (input: number, min: number, max: number) => {
  return Math.min(Math.max(input, min), max);
};

const range = (
  input: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number,
  clampOutput: boolean = true,
) => {
  const oldRange = oldMax - oldMin;
  const newRange = newMax - newMin;
  const newValue = ((input - oldMin) * newRange) / oldRange + newMin;
  return clampOutput === false
    ? newValue
    : clamp(newValue, Math.min(newMin, newMax), Math.max(newMin, newMax));
};

const round = (value: number, precision: number, fixed: boolean = false) => {
  const multiplier = Math.pow(10, precision || 0);
  const result = Math.round(value * multiplier) / multiplier;
  return fixed && result % 1 !== 0 ? Number(result.toFixed(precision)) : result;
};

const floorWithStep = (
  value: number,
  step: number,
  offset: number,
  precision: number = 1,
): number => {
  const diff = value - offset;
  const factor = Math.floor((diff * precision) / (step * precision));

  return offset + factor * step;
};

const roundToNearest10 = (value: number, roundMethod?: string) => {
  const roundFunction =
    roundMethod === 'ceil'
      ? Math.ceil
      : roundMethod === 'floor'
      ? Math.floor
      : Math.round;

  const absoluteValue = Math.abs(value);

  const roundedValue =
    absoluteValue < 10
      ? absoluteValue
      : absoluteValue < 100
      ? roundFunction(absoluteValue / 10) * 10
      : absoluteValue < 1000
      ? roundFunction(absoluteValue / 100) * 100
      : absoluteValue < 10_000
      ? roundFunction(absoluteValue / 1000) * 1000
      : absoluteValue < 100_000
      ? roundFunction(absoluteValue / 10_000) * 10_000
      : absoluteValue < 1_000_000
      ? roundFunction(absoluteValue / 100_000) * 100_000
      : absoluteValue < 10_000_000
      ? roundFunction(absoluteValue / 1_000_000) * 1_000_000
      : absoluteValue < 100_000_000
      ? roundFunction(absoluteValue / 10_000_000) * 10_000_000
      : absoluteValue < 1_000_000_000
      ? roundFunction(absoluteValue / 100_000_000) * 100_000_000
      : roundFunction(absoluteValue / 1_000_000_000) * 1_000_000_000;

  return Math.sign(value) < 0 ? -roundedValue : roundedValue;
};

const linearToLogarithmic = (linearValue: number, min: number, max: number) => {
  const logMin = Math.max(Math.log10(min), 0);

  const logMax = Math.log10(max);

  const logValue = linearValue * (logMax - logMin) + logMin;

  let value = Math.pow(10, logValue);

  if (value < min) {
    value = min;
  } else if (value > max) {
    value = max;
  }

  return Math.round(value);
};

const logarithmicToLinear = (
  originalValue: number,
  min: number,
  max: number,
) => {
  const normalizedValue = originalValue - min + 1;

  if (normalizedValue <= 0) {
    return 0;
  }

  if (originalValue >= max) {
    return 1;
  }

  const logMin = Math.max(Math.log10(min), 0);
  const logMax = Math.log10(max);
  const logValue = Math.max(Math.log10(originalValue), 0);

  return (logValue - logMin) / (logMax - logMin);
};

export {
  clamp,
  lerp,
  normalize,
  range,
  round,
  floorWithStep,
  roundToNearest10,
  linearToLogarithmic,
  logarithmicToLinear,
};
