import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Color } from '@packages/ui';
import { Metric, MetricProp, useAnalytics } from '@packages/analytics';
import { validate } from 'email-validator';

import { oAuthOptions } from '../../data/constants';

import { AuthCallbackProps } from 'main/components/auth/OAuthButton/OAuthButton';
import {
  SubmitButton,
  Form,
  FormHeader,
  Selection,
  TextInput,
  OAuth,
} from 'main/components';
import { getCookie } from 'shared/utils';
import {
  hCaptcha,
  TEXT_BRANDING,
  IS_FOUNDATION,
  IS_LOCAL_ENV,
  APP,
} from 'shared/config/Config';
import Account from 'shared/models/Account';
import { useRootStore } from 'shared/stores';
import Countries from 'shared/data/Countries';
// Models
import Logger from 'shared/models/Logger';
import History, { getQuery } from 'shared/models/History';
import { SignupTypes } from 'main/data/types/SignupTypes';

interface FeedbackType {
  text: string;
  type: string;
}

const hCaptchaProps = {
  endpoint: hCaptcha.endpoint,
  custom: IS_FOUNDATION,
  ...(IS_LOCAL_ENV
    ? {
        host: 'dashboard.local',
      }
    : {}),
};

const Signup = () => {
  const [feedback, setFeedback] = useState<FeedbackType>({
    text: '',
    type: '',
  });
  const [processing, setProcessing] = useState<boolean>(false);
  const {
    type: signupType = SignupTypes.Publisher,
    'aws-token': awsToken = null,
    promo: urlCouponCode,
  } = getQuery();
  const [token, setToken] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [country, setCountry] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const captchaRef = useRef<any>(null);
  const { t, i18n } = useTranslation('portal');
  const { trackMetric } = useAnalytics();
  const { language } = i18n;
  const { login } = useRootStore();

  const isHCaptchaInvisible =
    signupType === SignupTypes.Pro || signupType === SignupTypes.Accessibility;
  const hCaptchaSitekey =
    signupType === SignupTypes.Accessibility
      ? hCaptcha.passiveSitekey
      : hCaptcha.sitekey;

  useEffect(() => {
    if (isHCaptchaInvisible && token) {
      signUp();
    }

    if (isHCaptchaInvisible && error) {
      setFeedback({ text: t('Please contact support'), type: 'error' });
    }
  }, [token, signupType, error]); // eslint-disable-line react-hooks/exhaustive-deps

  const isOAuth = () =>
    signupType === SignupTypes.Publisher || signupType === SignupTypes.Pro;

  const onSubmit = async () => {
    if (processing) return;
    setProcessing(true);

    if ((!token && !isHCaptchaInvisible) || email === '' || country === '') {
      setFeedback({
        text: t('Please complete the form.'),
        type: 'error',
      });
      setProcessing(false);
    }

    if (isHCaptchaInvisible && captchaRef.current) {
      captchaRef.current.execute();
    } else {
      signUp();
    }
  };

  const signUp = async () => {
    if (!token) return;

    try {
      trackMetric(Metric.SIGN_UP, {
        type: signupType,
        method: MetricProp.METHOD_EMAIL,
      });

      await Account.signup(
        email,
        country,
        token,
        awsToken,
        signupType,
        getQuery().r || getCookie('r'),
        language,
      );
      await login();

      if (signupType === SignupTypes.Pro) {
        History.push('/payment/select-plan', { urlCouponCode });

        return;
      }

      if (signupType === SignupTypes.Publisher) {
        History.push('/secure-account');
      } else if (signupType === SignupTypes.Requester) {
        History.push('/jobs/new');
      } else if (signupType === SignupTypes.Accessibility) {
        History.push('/email_confirmation');
      } else {
        // default to webmaster
        History.push('/secure-account');
      }
    } catch (e) {
      Logger.error(`couldn't signUp in Signup.jsx: ${e}`);

      const errorMsg =
        e.message === 'Failed to fetch'
          ? t('Cannot contact the server')
          : t(e.message);
      setFeedback({ text: errorMsg, type: 'error' });
      setProcessing(false);

      // Reset hCaptcha if fails, only if reference is available
      if (captchaRef.current) {
        captchaRef.current.resetCaptcha();
        setToken('');
      }
    }
  };

  const handleOAuth = async ({ accountType, authType }: AuthCallbackProps) => {
    try {
      if (!captchaRef.current) {
        setFeedback({ text: 'Please try again.', type: 'error' });
        return;
      }

      resetFeedback();
      const MAX_URL_LENGTH = 4096;
      const { response } = (await captchaRef.current.execute({
        async: true,
      })) as { response: string };

      const isProSignup = accountType === SignupTypes.Pro;

      const proNextPage = encodeURIComponent('/payment/select-plan');
      const nextParam = isProSignup ? `next=${proNextPage}` : '';

      let oAuthURL = `${APP.endpoint}/oauth/${authType}?token=${response}${
        nextParam ? `&${nextParam}` : ''
      }&signup_page=Signup`;
      if (oAuthURL.length > MAX_URL_LENGTH) {
        Logger.error(`hCaptcha response token was too long.`);
        // To prevent blocking siging up, we will not send resposne token
        oAuthURL = `${APP.endpoint}/oauth/${authType}?${nextParam}&signup_page=Signup`;
      }

      window.location.href = oAuthURL;
    } catch (e) {
      const reason = t(e?.message ?? '');

      Logger.error(`Couldn't call ${authType} due to ${reason}`);
      setFeedback({ text: reason, type: 'error' });
    }
  };

  useEffect(() => {
    if (signupType === SignupTypes.Accessibility) {
      document.title = `Sign up for ${TEXT_BRANDING} Accessibility Access`;
      setCountry('US');
    }
  }, [signupType]);

  const handleKeyDown = (evt: KeyboardEvent) => {
    const { keyCode } = evt;
    if (keyCode === 13) {
      // If enter press
      onSubmit();
    }
  };

  const handleEmailChange = input => {
    setEmail(input);
  };

  const resetFeedback = () => setFeedback({ text: '', type: '' });

  const setInputFeedback = () => {
    if (!email) {
      return resetFeedback();
    }

    if (!validate(email)) {
      return setFeedback({
        text: t('Invalid email format'),
        type: 'error',
      });
    }

    return resetFeedback();
  };

  return (
    <Form
      ariaLabel={t('{{brand}} Signup', {
        brand: TEXT_BRANDING,
      })}
      width={300}
      padding={0}
    >
      {signupType !== SignupTypes.Accessibility ? (
        <FormHeader withUnderline={false} feedback={feedback}>
          {t('Sign Up')}
        </FormHeader>
      ) : (
        <FormHeader withUnderline={false} role="alert" feedback={feedback}>
          {t('Sign Up For {{brand}} Accessibility Access', {
            brand: TEXT_BRANDING,
          })}
        </FormHeader>
      )}
      {isOAuth() ? (
        <OAuth
          role="signup"
          signupType={signupType}
          buttons={oAuthOptions}
          onSelect={handleOAuth}
          showDivider
        />
      ) : (
        <></>
      )}
      <InputWrapper>
        <StyledLabel htmlFor="email">{t('Email')}</StyledLabel>
        <InputEmail
          id="email"
          placeholder={t('Email')}
          type="email"
          autoComplete="email"
          label="email"
          value={email}
          tabIndex="0"
          onBlur={setInputFeedback}
          onFocus={resetFeedback}
          onKeyDown={handleKeyDown}
          onChange={handleEmailChange}
          defaultBorderColor={
            signupType === SignupTypes.Accessibility ? 'darkGrey' : undefined
          }
          ariaRequired
        />
        {signupType !== SignupTypes.Accessibility && (
          <>
            <StyledLabel htmlFor="country">{t('Country')}</StyledLabel>
            <StyledSelection
              autoComplete="none"
              id="country"
              label="country"
              placeholder={t('Country / Region')}
              options={Countries}
              selected={country}
              onChange={setCountry}
              ariaRequired
            />
          </>
        )}
      </InputWrapper>

      {isHCaptchaInvisible ? (
        <HCaptchaInvisibleWrapper data-cy="hCaptchaWrapper" aria-hidden="true">
          <HCaptcha
            sitekey={hCaptchaSitekey}
            onVerify={setToken}
            onError={() => setError(true)}
            size="invisible"
            ref={captchaRef}
            // eslint-disable-next-line
            {...hCaptchaProps}
          />
        </HCaptchaInvisibleWrapper>
      ) : (
        <HCaptchaWrapper data-cy="hCaptchaWrapper">
          <HCaptcha
            sitekey={hCaptchaSitekey}
            onVerify={setToken}
            onError={() => setError(true)}
            size="normal"
            ref={captchaRef}
            // eslint-disable-next-line
            {...hCaptchaProps}
          />
        </HCaptchaWrapper>
      )}

      {signupType === SignupTypes.Accessibility ? (
        <Text>
          {t(
            'Please click Submit and then check your email. Note our terms and conditions below.',
          )}
        </Text>
      ) : (
        <></>
      )}

      <ButtonWrapper>
        <Details aria-label={t('Terms and conditions')}>
          {signupType !== SignupTypes.Accessibility
            ? t(
                'By entering your email to sign up, you agree to our Terms and to receive service-related emails from us.',
              )
            : t(
                'By entering your email to sign up, you agree to our Terms and to receive service-related emails from us. We will never use your email for any other purpose than enabling accessibility access and preventing abuse.',
              )}
        </Details>
        <SubmitButton
          onClick={onSubmit}
          aria-live="polite"
          aria-relevant="text"
          aria-label={processing ? t('Submitting...') : t('Submit')}
          disabled={
            (!isHCaptchaInvisible && !token) || processing || !validate(email)
          }
        >
          {processing ? t('Submitting...') : t('Submit')}
        </SubmitButton>
      </ButtonWrapper>
    </Form>
  );
};

const InputWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const InputEmail = styled(TextInput)`
  margin-top: 5px;
  margin-bottom: 15px;
  position: relative;
`;

const HCaptchaWrapper = styled.div``;

// stop Safari VoiceOver from navigating into invisible area
const HCaptchaInvisibleWrapper = styled.div`
  display: none;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.div`
  margin-top: 20px;
  font-size: var(--defaultFontSize);
  font-weight: 300;
  line-height: 24px;
  overflow-wrap: break-word;
  text-align: center;
  color: black;
`;

const StyledLabel = styled.label`
  color: ${Color.grey600};
  font-weight: 500;
`;

const StyledSelection = styled(Selection)`
  margin-top: 5px;
`;

const Details = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: ${Color.black};
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export default observer(Signup);
