import { createBrowserHistory } from 'history';

let currentPath = '';
const History = createBrowserHistory();
export default History;

History.listen(location => {
  currentPath = location.pathname;
});
export function setQuery(params: Record<string, any>) {
  const queries = Object.keys(params)
    .filter(key => params[key] !== null && params[key] !== '')
    .map(key => [key, encodeURIComponent(params[key])].join('='));
  History.push(`${currentPath}?${queries.join('&')}`);
}

export function patchQuery(params: Record<string, any>) {
  setQuery({
    ...getQuery(),
    ...params,
  });
}
export function getQuery(params: string | Array<string> = []): any {
  const { search } = History.location;
  const keys = !Array.isArray(params) ? [params] : params;
  if (!search.includes('?'))
    return Object.assign(
      {},
      ...keys.map(key => ({
        [key]: false,
      })),
    );
  const queryString = search.split('?')[1];
  const queryArray = queryString.split('&');
  const queries = queryArray.reduce((arry, query) => {
    const [name, value] = query.split('=');
    return { ...arry, [name]: value ? decodeURIComponent(value) : false };
  }, {});

  if (keys.length > 0) {
    // $FlowFixMe
    return Object.assign(
      {},
      ...keys.map(key => ({
        [key]: queries[key] ? queries[key] : false,
      })),
    );
  }

  return queries;
}
export function setHash() {}
export function getHash() {}
